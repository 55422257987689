import find from "lodash/find"
import isNil from "lodash/isNil"


// Libs
import History from "@biuwer/core/src/history";
import { EMPTY_VALUE, getFilterExpressionFromURLParam } from '@biuwer/filters/src/filters-lib';

function getQueryFilters (queryId, filters = [], filterQueriesFieldPath = ["queries"], cardId, popupFilters) {

    const queryFilters = [];
    const history = History.getHistory();
    if (popupFilters?.size > 0) popupFilters = popupFilters.toJS();

    for (let filter of filters) {

        const filterQuery = filter
            .getIn(filterQueriesFieldPath)
            .find(queryFilter => (
                queryFilter && queryFilter.get('query') && (queryId && queryId === queryFilter.get("query")) &&
                (cardId && cardId !== "new" ? cardId === queryFilter.get("_id") : true)
            ))

        if (filterQuery == null) continue

        // Query filter object
        const queryFilter = {
            // Filter metadata
            _id: filter.get('_id'),
            affected_filters: filter.get('affected_filters').toJS(),
            custom_list: filter.get('custom_list').toJS(),
            data_type: filter.get('data_type'),
            expression: filter.get('expression').toJS(),
            filter_type: filter.get('filter_type'),
            mandatory: filter.get('mandatory'),
            order: (filter.get('order') && filter.get("order").toJS()) || {},
            stage: filter.get('stage'),
            time_metric: filter.get('time_metric'),
            url_param: filter.get('url_param').toJS(),
            multi_value: filter.get('multi_value'),
            // Filter query metadata
            dataset: filterQuery.get('dataset'),
            data_field: filterQuery.get('data_field'),
        };

        // Add time format and level if the filter has a time metric
        if (queryFilter.time_metric) {
            queryFilter.time_format = filter.get('time_format');
            queryFilter.time_level = filter.get('time_level');
        }

        // Avoid sending unnecessary values in case of 'inList' + allValues
        if (queryFilter.expression) {
            const values = queryFilter.expression.values.map(value => value === EMPTY_VALUE ? null : value).filter(value => value != null)

            // If all values is selected, delete possible expression values
            if (queryFilter.expression.operation === 'inList' && queryFilter.expression.all_values) {
                queryFilter.expression.values = [];
            }
            // If filter is not mandatory and no value has been configured, apply all values
            if (!queryFilter.mandatory && values.length === 0 && !queryFilter.expression.all_values) {
                queryFilter.expression.all_values = true;
            }

        }

        // Take default expression if there is no custom expression defined
        // Check custom list
        if (filter.get('expression') && !filter.getIn(['expression', 'operation'])) {
            if (filter.get('use_custom_list') && filter.get('custom_list') && filter.getIn(['default_expression', 'all_values'])) {
                queryFilter.expression = {
                    ...queryFilter.expression,
                    all_values: false,
                    operation: filter.getIn(['default_expression', 'operation']),
                    values: filter.get('custom_list').toJS(),
                    translations: filter.getIn(['default_expression', 'translations']) || []
                };
                // Add date level to query filter root
                if (queryFilter.expression.date_level && filter.getIn(['default_expression', 'date_level'])) {
                    queryFilter.date_level = filter.getIn(['default_expression', 'date_level']);
                }
            } else {
                queryFilter.expression = filter.getIn(['default_expression']).toJS();
            }
        } else if (filter.get('use_custom_list') && filter.get('custom_list') && filter.getIn(['expression', 'all_values'])) {
            queryFilter.expression = {
                ...queryFilter.expression,
                all_values: false,
                operation: filter.getIn(['expression', 'operation']),
                values: filter.get('custom_list').toJS(),
                translations: filter.getIn(['default_expression', 'translations']) || []
            }
        }

        // Check url param
        if (queryFilter.url_param && !!queryFilter.url_param.enabled) {
            const URLParsedExpression = getFilterExpressionFromURLParam(queryFilter, history);
            if (!isNil(URLParsedExpression)) {
                queryFilter.expression = URLParsedExpression;
            }
        }

        // Apply popup filters if needed
        if (popupFilters?.length > 0) {
            let popupFilter = find(popupFilters, { _id: queryFilter._id });
            if (popupFilter) {
                queryFilter.expression = popupFilter.expression;
                queryFilter.stage = popupFilter.stage;
            }
        }

        const values = queryFilter.expression.values.map(value => value === EMPTY_VALUE ? null : value).filter(value => value != null)
        if (values.length === 0 && queryFilter.expression.values.length !== values.length && !["isNull", "isNotNull"].includes(queryFilter.expression.operation)) {
            queryFilter.expression.values = []
            queryFilter.expression.operation = "inList"
            queryFilter.expression.all_values = true
        }

        if (!filter.get("dont_filter") && filter.getIn(["expression", "values"]).size > 0) {
            queryFilter.expression.values = filter.getIn(["expression", "values"]).toJS().map(value => value === EMPTY_VALUE ? null : value).filter(value => value != null)
            queryFilter.expression.operation = filter.getIn(["expression", "operation"]) ?? "inList"
            queryFilter.expression.all_values = false
        }

        // Add filter
        if (!queryFilter.expression.all_values || queryFilter.use_custom_list) {
            queryFilters.push(queryFilter);
        }
    }

    return queryFilters;
}

export default getQueryFilters;

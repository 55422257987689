import { cloneDeep, uniq } from "lodash";

// Libs
import expressionParser from "./expression-parser";
import operationsLib from "./calculated-fields-lib/operations-lib";
import { isAggregation } from "./calculated-fields-lib/aggregation-operations";

export default function getLocalQueryFieldsTree({ startFieldsArray, queryFields, calculatedFields, timeWindowFields, localDatasets }) {

    // Fields tree
    let fieldsTree = [];

    // Recursive function to generate fields tree
    const setFieldsTreeFn = (fieldsArray) => {
        fieldsArray.forEach(field => {
            const fieldQuery = (field.queryField && field.queryField.query) || field.query;
            const isQueryField = fieldQuery !== "CF";
            const fieldPosition = (field.queryField && field.queryField.position) || field.position;
            const queryField = queryFields.find(qf => qf.query === fieldQuery && qf.position === fieldPosition);
            const newFieldTree = {
                query: fieldQuery,
                position: fieldPosition,
                field_type: queryField?.field_type,
                data_type: queryField?.data_type,
                refFieldId: isQueryField ? queryField?.cfId : field?.fieldId,
                fieldId: field?.fieldId,
                childs: [],
                operation: {}
            };

            let fieldLocalDatasets = [];
            localDatasets?.forEach((ld) => {
                if (ld?.queries?.includes(fieldQuery)) fieldLocalDatasets.push(ld?._id);
            });

            if (fieldQuery === "CF") {

                const cf = calculatedFields.find(cf => cf.position === fieldPosition) || {};
                const fieldChilds = [];

                const getQueryField = field => queryFields.find(cf => cf.query === field.query && cf.position === field.position);
                const replaceFieldProps = (field, queryField) => {
                    field.fieldId = queryField && queryField.cfId;
                    field.field_type = queryField && queryField.field_type;
                    return field;
                };
                const addFieldChild = (field) => {
                    if (!fieldChilds.some(fc => fc.query === field.query && fc.position === field.position)) {
                        fieldChilds.push(field);
                    }
                };
                function parseField(field) {
                    const queryField = getQueryField(field);
                    field = replaceFieldProps(field, queryField);
                    addFieldChild(field);
                    return field.fieldId;
                }

                const getFieldChildsFromParsedExpression = (parsedExpression) => {
                    if (!parsedExpression) return;
                    if (parsedExpression.type === "field") {
                        parsedExpression.field = parseField(parsedExpression.field);
                    } else if (parsedExpression.type === "fields" && parsedExpression.fieldsLength > 0) {
                        for (let i = 1; i < parsedExpression.fieldsLength + 1; i++) {
                            parsedExpression[`field${i}`] = parseField(parsedExpression[`field${i}`]);
                        }
                    }
                    else if (parsedExpression.type === "operation") {
                        const peOperation = parsedExpression.operation;
                        operationsLib.operationFunctions({
                            operation: peOperation,
                            fn: getFieldChildsFromParsedExpression,
                            timeWindowFn: extraArgs => {
                                // Date field
                                let dateField = extraArgs.dateField;
                                const dateCardField = getQueryField(dateField);
                                extraArgs.dateMask = dateCardField.date_mask;
                                dateField = replaceFieldProps(dateField, dateCardField);
                                addFieldChild(dateField);
                                extraArgs.dateField = dateField.fieldId;
                                // Metric field
                                extraArgs.metricField = parseField(extraArgs.metricField);
                            },
                        });
                    }
                };

                newFieldTree.operation = cf.operation || {};
                newFieldTree.parsedExpression = expressionParser.parseExpression(cf.expression);
                newFieldTree.isTimeWindowField = ["prev_year", "prev_quarter", "prev_month", "prev_week", "prev_day", "prev_period"].includes(newFieldTree.parsedExpression?.operation?.type);
                newFieldTree.isAggregationField = isAggregation(cf.expression, calculatedFields);
                getFieldChildsFromParsedExpression(newFieldTree.parsedExpression);
                newFieldTree.childs = fieldChilds;

                if (fieldChilds?.length > 0) {
                    fieldChilds.forEach((fc) => {
                        localDatasets?.forEach((ld) => {
                            if (ld?.queries?.includes(fc?.query)) fieldLocalDatasets.push(ld?._id);
                        });
                    })
                }
            }
            newFieldTree.localDatasets = uniq(fieldLocalDatasets);
            setFieldsTreeFn(newFieldTree.childs);
            fieldsTree.push(newFieldTree);       
        });
    };

    setFieldsTreeFn(startFieldsArray);

    const twFields = fieldsTree.reduce((acc, next) => {
        if (next.query !== "CF" || !next.parsedExpression) return acc;
        function checkParsedExpression(parsedExpression) {
            if (parsedExpression.type === 'operation') {
                const peOperation = parsedExpression.operation;
                operationsLib.operationFunctions({
                    operation: peOperation,
                    fn: checkParsedExpression,
                    timeWindowFn: extraArgs => {
                        acc.push({
                            refFieldId: next.refFieldId,
                            type: peOperation.type,
                            timeField: extraArgs.dateField,
                            metricField: extraArgs.metricField,
                            position: next.position
                        });
                    },
                });
            }
        }
        checkParsedExpression(next.parsedExpression);
        return acc;
    }, []);

    twFields.forEach((twField) => {

        const twFieldIndex = fieldsTree.findIndex((ft) => ft.refFieldId === twField.refFieldId && ft.query === "CF");
        if (twFieldIndex > -1) {
            fieldsTree[twFieldIndex].isTimeWindowField = true;
            fieldsTree[twFieldIndex].queryTW = `TWQ${twField.position}`;
        }
    });



    // Get uniq fields of fields tree
    let uniqFieldsTree = [];
    for (let field of fieldsTree) {
        if (uniqFieldsTree.findIndex(f => f.fieldId === field.fieldId) !== -1) continue;
        // if (uniqFieldsTree.findIndex(f => f.query === field.query && f.position === field.position) !== -1) continue;
        uniqFieldsTree.push(field);
    }

    // Add time window metric fields
    if (Array.isArray(timeWindowFields) && timeWindowFields.length > 0) {

        for (let twMetricField of timeWindowFields) {
            const metricField = uniqFieldsTree.find(f => f.refFieldId === twMetricField);
            uniqFieldsTree.push({
                ...metricField,
                fieldId: getTimeWindowMetricFieldId(twMetricField),
                refFieldId: getTimeWindowMetricFieldId(twMetricField),
                isTWMetric: true,
            });
        }

        // Get time window calculated fields
        const newCalculatedFields = [];
        function addTWCFields(twFields) {
            const newTWFields = [];
            for (let calculatedField of uniqFieldsTree.filter(f => f.query === "CF" && f.childs.some(fc => twFields.includes(fc.fieldId)))) {
                const newCalculatedField = cloneDeep(calculatedField);
                let hasTimeOperation = false;
                // Parse time window fields of the new calculated field
                newCalculatedField.fieldId = getTimeWindowMetricFieldId(newCalculatedField.fieldId);
                newCalculatedField.refFieldId = getTimeWindowMetricFieldId(newCalculatedField.refFieldId);
                newCalculatedField.isTWMetric = true;
                for (let fieldChild of newCalculatedField.childs) {
                    if (twFields.includes(fieldChild.fieldId)) {
                        fieldChild.fieldId = getTimeWindowMetricFieldId(fieldChild.fieldId);
                    }
                }
                function parseTimeWindowFields(parsedExpression) {
                    if (parsedExpression.type === "field") {
                        parsedExpression.field = getTimeWindowMetricFieldId(parsedExpression.field);
                    }
                    else if (parsedExpression.type === "operation") {
                        const peOperation = parsedExpression.operation;
                        operationsLib.operationFunctions({
                            operation: peOperation,
                            fn: parseTimeWindowFields,
                            timeWindowFn: extraArgs => {
                                extraArgs.metricField = getTimeWindowMetricFieldId(extraArgs.metricField);
                                hasTimeOperation = true;
                            },
                        });
                    }
                }
                parseTimeWindowFields(newCalculatedField.parsedExpression);
                // If this calculated field has a time operation parse metric field of the original calculated field
                if (hasTimeOperation) {
                    function parseTimeOperationMetricField(parsedExpression) {
                        if (parsedExpression.type === "operation") {
                            const peOperation = parsedExpression.operation;
                            operationsLib.operationFunctions({
                                operation: peOperation,
                                fn: parseTimeOperationMetricField,
                                timeWindowFn: extraArgs => {
                                    extraArgs.metricField = getTimeWindowMetricFieldId(extraArgs.metricField);
                                    extraArgs.isTWMetricField = true;
                                },
                            });
                        }
                    }
                    parseTimeOperationMetricField(calculatedField.parsedExpression);
                }
                newTWFields.push(calculatedField.fieldId);
                newCalculatedFields.push(newCalculatedField);            
            }
            if (newTWFields.length > 0) {
                addTWCFields(newTWFields);
            }
        }
        addTWCFields(timeWindowFields);

        uniqFieldsTree = uniqFieldsTree.concat(newCalculatedFields);

    }

    // find localDatasets for calculated fields
    uniqFieldsTree.map((ft) => {
        if (ft?.query === "CF" && ft?.childs?.length > 1) {
            let localDatasets = [];
            ft.childs.forEach((child) => {
                const childLocalDatasets = uniqFieldsTree.find(field => field?.query === child?.query && field?.position === child?.position)?.localDatasets;
                localDatasets = localDatasets.concat(childLocalDatasets);
            });

            ft.localDatasets = uniq(localDatasets);
        }
        return ft;
    });

    return uniqFieldsTree;

}

// Function to get the time window metric field id of the specified field id
function getTimeWindowMetricFieldId(fieldId) {
    return `twq${fieldId}`;
}

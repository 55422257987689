import { fromJS } from "immutable";

// Libs
import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import i18n from '@biuwer/core/src/i18n';

// Actions
import { useDCEActions } from "@biuwer/redux/src/system/data-card-editor/data-card-editor-actions";

// TODO: cambiar los listados de idiomas que se usan en la aplicación
export const biuwerUILanguages = [
    { code: 'es', name: 'Spanish', i18n: {} },
    { code: 'en', name: 'English' },
];

export const translationLanguages = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Spanish' },
    { value: 'fr', label: 'French' },
    { value: 'pt', label: 'Portuguese' },
    { value: 'de', label: 'German' },
    { value: 'it', label: 'Italian' },
    { value: 'zh', label: 'Chinese' },
    { value: 'hi', label: 'Hindi' },
    { value: 'ar', label: 'Arabic' },
    { value: 'bn', label: 'Bengali' },
    { value: 'ab', label: 'Abkhazian' },
    { value: 'aa', label: 'Afar' },
    { value: 'af', label: 'Afrikaans' },
    { value: 'ak', label: 'Akan' },
    { value: 'sq', label: 'Albanian' },
    { value: 'am', label: 'Amharic' },
    { value: 'an', label: 'Aragonese' },
    { value: 'hy', label: 'Armenian' },
    { value: 'as', label: 'Assamese' },
    { value: 'av', label: 'Avaric' },
    { value: 'ae', label: 'Avestan' },
    { value: 'ay', label: 'Aymara' },
    { value: 'az', label: 'Azerbaijani' },
    { value: 'bm', label: 'Bambara' },
    { value: 'ba', label: 'Bashkir' },
    { value: 'eu', label: 'Basque' },
    { value: 'be', label: 'Belarusian' },
    { value: 'bi', label: 'Bislama' },
    { value: 'bs', label: 'Bosnian' },
    { value: 'br', label: 'Breton' },
    { value: 'bg', label: 'Bulgarian' },
    { value: 'my', label: 'Burmese' },
    { value: 'ca', label: 'Catalan, Valencian' },
    { value: 'ch', label: 'Chamorro' },
    { value: 'ce', label: 'Chechen' },
    { value: 'ny', label: 'Chichewa, Chewa, Nyanja' },
    { value: 'cu', label: 'Church Slavonic, Old Slavonic, Old Church Slavonic' },
    { value: 'cv', label: 'Chuvash' },
    { value: 'kw', label: 'Cornish' },
    { value: 'co', label: 'Corsican' },
    { value: 'cr', label: 'Cree' },
    { value: 'hr', label: 'Croatian' },
    { value: 'cs', label: 'Czech' },
    { value: 'da', label: 'Danish' },
    { value: 'dv', label: 'Divehi, Dhivehi, Maldivian' },
    { value: 'nl', label: 'Dutch, Flemish' },
    { value: 'dz', label: 'Dzongkha' },
    { value: 'eo', label: 'Esperanto' },
    { value: 'et', label: 'Estonian' },
    { value: 'ee', label: 'Ewe' },
    { value: 'fo', label: 'Faroese' },
    { value: 'fj', label: 'Fijian' },
    { value: 'fi', label: 'Finnish' },
    { value: 'fy', label: 'Western Frisian' },
    { value: 'ff', label: 'Fulah' },
    { value: 'gd', label: 'Gaelic, Scottish Gaelic' },
    { value: 'gl', label: 'Galician' },
    { value: 'lg', label: 'Ganda' },
    { value: 'ka', label: 'Georgian' },
    { value: 'el', label: 'Greek, Modern' },
    { value: 'kl', label: 'Kalaallisut, Greenlandic' },
    { value: 'gn', label: 'Guarani' },
    { value: 'gu', label: 'Gujarati' },
    { value: 'ht', label: 'Haitian, Haitian Creole' },
    { value: 'ha', label: 'Hausa' },
    { value: 'he', label: 'Hebrew' },
    { value: 'hz', label: 'Herero' },
    { value: 'ho', label: 'Hiri Motu' },
    { value: 'hu', label: 'Hungarian' },
    { value: 'is', label: 'Icelandic' },
    { value: 'io', label: 'Ido' },
    { value: 'ig', label: 'Igbo' },
    { value: 'id', label: 'Indonesian' },
    { value: 'ia', label: 'Interlingua (International Auxiliary Language Association)' },
    { value: 'ie', label: 'Interlingue, Occidental' },
    { value: 'iu', label: 'Inuktitut' },
    { value: 'ik', label: 'Inupiaq' },
    { value: 'ga', label: 'Irish' },
    { value: 'ja', label: 'Japanese' },
    { value: 'jv', label: 'Javanese' },
    { value: 'kn', label: 'Kannada' },
    { value: 'kr', label: 'Kanuri' },
    { value: 'ks', label: 'Kashmiri' },
    { value: 'kk', label: 'Kazakh' },
    { value: 'km', label: 'Central Khmer' },
    { value: 'ki', label: 'Kikuyu, Gikuyu' },
    { value: 'rw', label: 'Kinyarwanda' },
    { value: 'ky', label: 'Kirghiz, Kyrgyz' },
    { value: 'kv', label: 'Komi' },
    { value: 'kg', label: 'Kongo' },
    { value: 'ko', label: 'Korean' },
    { value: 'kj', label: 'Kuanyama' },
    { value: 'ku', label: 'Kurdish' },
    { value: 'lo', label: 'Lao' },
    { value: 'la', label: 'Latin' },
    { value: 'lv', label: 'Latvian' },
    { value: 'li', label: 'Limburgan' },
    { value: 'ln', label: 'Lingala' },
    { value: 'lt', label: 'Lithuanian' },
    { value: 'lu', label: 'Luba-Katanga' },
    { value: 'lb', label: 'Luxembourgish, Letzeburgesch' },
    { value: 'mk', label: 'Macedonian' },
    { value: 'mg', label: 'Malagasy' },
    { value: 'ms', label: 'Malay' },
    { value: 'ml', label: 'Malayalam' },
    { value: 'mt', label: 'Maltese' },
    { value: 'gv', label: 'Manx' },
    { value: 'mi', label: 'Maori' },
    { value: 'mr', label: 'Marathi' },
    { value: 'mh', label: 'Marshallese' },
    { value: 'mn', label: 'Mongolian' },
    { value: 'na', label: 'Nauru' },
    { value: 'nv', label: 'Navajo, Navaho' },
    { value: 'nd', label: 'North Ndebele' },
    { value: 'nr', label: 'South Ndebele' },
    { value: 'ng', label: 'Ndonga' },
    { value: 'ne', label: 'Nepali' },
    { value: 'no', label: 'Norwegian' },
    { value: 'nb', label: 'Norwegian Bokmål' },
    { value: 'nn', label: 'Norwegian Nynorsk' },
    { value: 'ii', label: 'Sichuan Yi, Nuosu' },
    { value: 'oc', label: 'Occitan' },
    { value: 'oj', label: 'Ojibwa' },
    { value: 'or', label: 'Oriya' },
    { value: 'om', label: 'Oromo' },
    { value: 'os', label: 'Ossetian, Ossetic' },
    { value: 'pi', label: 'Pali' },
    { value: 'ps', label: 'Pashto, Pushto' },
    { value: 'fa', label: 'Persian' },
    { value: 'pl', label: 'Polish' },
    { value: 'pa', label: 'Punjabi' },
    { value: 'qu', label: 'Quechua' },
    { value: 'ro', label: 'Romanian' },
    { value: 'rm', label: 'Romansh' },
    { value: 'rn', label: 'Rundi' },
    { value: 'ru', label: 'Russian' },
    { value: 'se', label: 'Northern Sami' },
    { value: 'sm', label: 'Samoan' },
    { value: 'sg', label: 'Sango' },
    { value: 'sa', label: 'Sanskrit' },
    { value: 'sc', label: 'Sardinian' },
    { value: 'sr', label: 'Serbian' },
    { value: 'sn', label: 'Shona' },
    { value: 'sd', label: 'Sindhi' },
    { value: 'si', label: 'Sinhala, Sinhalese' },
    { value: 'sk', label: 'Slovak' },
    { value: 'sl', label: 'Slovenian' },
    { value: 'so', label: 'Somali' },
    { value: 'st', label: 'Southern Sotho' },
    { value: 'su', label: 'Sundanese' },
    { value: 'sw', label: 'Swahili' },
    { value: 'ss', label: 'Swati' },
    { value: 'sv', label: 'Swedish' },
    { value: 'tl', label: 'Tagalog' },
    { value: 'ty', label: 'Tahitian' },
    { value: 'tg', label: 'Tajik' },
    { value: 'ta', label: 'Tamil' },
    { value: 'tt', label: 'Tatar' },
    { value: 'te', label: 'Telugu' },
    { value: 'th', label: 'Thai' },
    { value: 'bo', label: 'Tibetan' },
    { value: 'ti', label: 'Tigrinya' },
    { value: 'to', label: 'Tonga (Tonga Islands)' },
    { value: 'ts', label: 'Tsonga' },
    { value: 'tn', label: 'Tswana' },
    { value: 'tr', label: 'Turkish' },
    { value: 'tk', label: 'Turkmen' },
    { value: 'tw', label: 'Twi' },
    { value: 'ug', label: 'Uighur, Uyghur' },
    { value: 'uk', label: 'Ukrainian' },
    { value: 'ur', label: 'Urdu' },
    { value: 'uz', label: 'Uzbek' },
    { value: 've', label: 'Venda' },
    { value: 'vi', label: 'Vietnamese' },
    { value: 'vo', label: 'Volapük' },
    { value: 'wa', label: 'Walloon' },
    { value: 'cy', label: 'Welsh' },
    { value: 'wo', label: 'Wolof' },
    { value: 'xh', label: 'Xhosa' },
    { value: 'yi', label: 'Yiddish' },
    { value: 'yo', label: 'Yoruba' },
    { value: 'za', label: 'Zhuang, Chuang' },
    { value: 'zu', label: 'Zulu' }
];

export function useObjectTranslations() {
    const { setCardTranslations } = useDCEActions();

    const updateTranslations = ({ object, translations }) => {
        switch (object) {
            case "card":
                setCardTranslations(translations);
                break;
            default:
                break;
        }
    };

    return updateTranslations;
}

export function getLanguageFormat(organizationSettings, language) {

    const languageToTranslate = language || getLanguageToTranslate();
    const languagesSettings = organizationSettings?.multi_language ? [
        ...organizationSettings?.multi_language?.languages,
        {
            language: organizationSettings?.language,
            currency: organizationSettings?.currency,
            date_format: organizationSettings?.date_format,
            number_format: organizationSettings?.number_format,
        }
    ] : [{
        language: organizationSettings?.language,
        currency: organizationSettings?.currency,
        date_format: organizationSettings?.date_format,
        number_format: organizationSettings?.number_format,
    }];

    return fromJS(languagesSettings.find(lang => lang.language === languageToTranslate));
}

/**
 * Function to get the language when is no selected by user to translate
 * @returns {String} Language to translate like es, en, fr...
 */
export function getLanguageToTranslate() {
    const user = Auth.getUser();
    const currentOrganization = Auth.getCurrentOrganization();

    const urlParams = new URLSearchParams(window.location.search);
    const paramLanguage = urlParams.get('language')?.substring(0, 2);

    let browserLanguage = "";
    const useBrowserLanguage = currentOrganization?.settings?.multi_language?.use_browser_language;
    if (useBrowserLanguage) browserLanguage = navigator?.language?.substring(0, 2);

    const organizationLanguage = currentOrganization?.settings?.language;

    // The priority order is param value, browser language if has enabled, user language and last the organization language
    return paramLanguage || browserLanguage || user.settings.language || organizationLanguage;
}

/**
 * Function to get a field translation
 * @param {String} field Field to match translations in i18n array
 * @param {String} language Language to translate
 * @param {Array} translations i18n object with all object fields translations
 * @param {String} defaultValue Value to return when there is no field translation
 * @returns {String} Field translation
 */
export function getFieldTranslation({ field, language, translations, defaultValue }) {
    const languageToTranslate = language || getLanguageToTranslate();

    // Change biuwer language if the language to translate is in Biuwer UI languages list and not is the current i18n language
    if (
        biuwerUILanguages.find(lang => lang.code === languageToTranslate) &&
        i18n.language !== languageToTranslate
    ) i18n.changeLanguage(languageToTranslate);

    if (translations?.length > 0) {
        const fieldValue = translations.find(t => t.field === field && t.language === languageToTranslate)?.value;
        return fieldValue || defaultValue || "";
    } else {
        return defaultValue || "";
    }
}




/**
 * Function which translate card fields to export card data in excel and csv formats
 * @param {Object} card - Card meta data
 * @param {Array} fields - Card fields to translate
 * @param {Array} translations - Card i18n object with translations
 * @param {String} language - Language to translate card fields
 */
export function translateCardFields({ card, fields, translations, language }) {
    let translatedFields = fields;

    translatedFields = translatedFields.map(field => {
        const fieldKey = field.fieldKey;
        switch (card.card_type) {
            case "VerticalTable":
                return {
                    ...field,
                    fieldLabel: getFieldTranslation({
                        field: `column_${fieldKey}_title`,
                        language,
                        translations,
                        defaultValue: field.fieldLabel
                    })
                };
            case "Chart":
                // Translate dimension field label
                const dimConfig = card.visualization?.dimension;
                if (fieldKey === dimConfig?.local_query_field?.fieldId) {
                    return {
                        ...field,
                        fieldLabel: getFieldTranslation({
                            field: "dimension_label",
                            language,
                            translations,
                            defaultValue: field.fieldLabel
                        })
                    };
                }

                // Translate heatmat field label
                const heatmapConfig = card.visualization?.heatmap;
                if (fieldKey === heatmapConfig?.local_query_field?.fieldId) {
                    return {
                        ...field,
                        fieldLabel: getFieldTranslation({
                            field: "heatmap_label",
                            language,
                            translations,
                            defaultValue: field.fieldLabel
                        })
                    }
                }

                // Translate bullet threshold field label
                const thresholdConfig = card.visualization?.threshold;
                if (fieldKey === thresholdConfig?.local_query_field?.fieldId) {
                    return {
                        ...field,
                        fieldLabel: getFieldTranslation({
                            field: "threshold_label",
                            language,
                            translations,
                            defaultValue: field.fieldLabel
                        })
                    }
                }

                // Translate radius field label
                const radiusConfig = card.visualization?.radius;
                if (fieldKey === radiusConfig?.local_query_field?.fieldId) {
                    return {
                        ...field,
                        fieldLabel: getFieldTranslation({
                            field: "radius_label",
                            language,
                            translations,
                            defaultValue: field.fieldLabel
                        })
                    }
                }

                // Translate metric field label
                const metrics = card.visualization?.metrics;
                if (metrics.find(metric => metric?.local_query_field?.fieldId === fieldKey)) {
                    return {
                        ...field,
                        fieldLabel: getFieldTranslation({
                            field: `metrics_${fieldKey}_label`,
                            language,
                            translations,
                            defaultValue: field.fieldLabel
                        })
                    };
                }

                // Translate complements label
                const complements = card.visualization?.complements;
                if (complements.config.find(complement => complement?.local_query_field?.fieldId === fieldKey)) {
                    return {
                        ...field,
                        fieldLabel: getFieldTranslation({
                            field: `complement_${fieldKey}_label`,
                            language,
                            translations,
                            defaultValue: field.fieldLabel
                        })
                    }
                }

                return field;
            case "CrossTable":
                const fieldTranslations = translations.find(obj => obj.field.includes(`${fieldKey}_label`));
                if (fieldTranslations) {
                    return {
                        ...field,
                        fieldLabel: getFieldTranslation({
                            field: fieldTranslations.field,
                            language,
                            translations,
                            defaultValue: field.fieldLabel
                        })
                    }
                }

                return field;
            default:
                return field;
        }
    });

    return translatedFields;
}

/**
 * Function to get multiple fields translation at the same time
 * @param {Array} fields Field names to match translations in i18n array -> Example (["name", "desc", "description"])
 * @param {String} language Language to translate
 * @param {Array} translations i18n object with all object fields translations
 * @param {Object} defaultValue Object with a value to return when there is no field translation -> Example({ name: 'nameValue', desc: 'descValue',description: 'descriptionValue'})
 * @returns {Object} Object with field translations
 */
export function getMultiFieldTranslation({ fields, language, translations, defaultValues}) {

    const languageToTranslate = language || getLanguageToTranslate();

    // Change biuwer language if the language to translate is in Biuwer UI languages list and not is the current i18n language
    if (
        biuwerUILanguages.find(lang => lang.code === languageToTranslate) &&
        i18n.language !== languageToTranslate
    ) i18n.changeLanguage(languageToTranslate);

    if (translations?.length > 0) {

        let translatedFields = {};
        fields.forEach((f) => {
            const fieldValue = translations.find(t => t.field === f && t.language === languageToTranslate)?.value;
            translatedFields[f] = fieldValue || defaultValues[f];
        });

        return translatedFields;
    } else {
        return defaultValues;
    }
}

/**
 * Function to translate card meta
 * @param {Map} card meta
 * @param {String} language to translate es, en, fr...
 * @returns
 */
export function translateCardMeta({ card, language }) {
    let translatedCardMeta = card?.toJS();
    const translations = translatedCardMeta.i18n;

    translatedCardMeta.name = getFieldTranslation({
        field: "name",
        language,
        translations,
        defaultValue: translatedCardMeta.name
    });

    translatedCardMeta.desc = getFieldTranslation({
        field: "desc",
        language,
        translations,
        defaultValue: translatedCardMeta.desc
    });

    translatedCardMeta.title = getFieldTranslation({
        field: "title",
        language,
        translations,
        defaultValue: translatedCardMeta.title
    });

    // Translate actions name
    const cardActions = translatedCardMeta.actions || [];
    translatedCardMeta.actions = cardActions.map(action => {
        let translatedAction = {
            ...action,
            action_name: getFieldTranslation({
                field: `action_${action._id}`,
                language,
                translations,
                defaultValue: action.action_name
            })
        };

        if (action.action_type === "url_link") {
            translatedAction.config.alternative_text = getFieldTranslation({
                field: `action_${action._id}_alt_text`,
                language,
                translations,
                defaultValue: action.config.alternative_text
            });
        }

        return translatedAction;
    });

    // Translate metrics for Chart and CrossTable cards
    if (["Chart", "CrossTable"].includes(translatedCardMeta.card_type)) {
        const metrics = translatedCardMeta.visualization?.metrics;
        for (const metric of metrics) {
            const metricId = metric.local_query_field.fieldId;

            const metricLabel = metric?.label;
            const customNullValue = metric?.null_config?.custom_value;

            if (metricLabel) {
                metric.label = getFieldTranslation({
                    field: `metrics_${metricId}_label`,
                    language,
                    translations,
                    defaultValue: metricLabel
                });
            }

            if (customNullValue) {
                metric.null_config.custom_value = getFieldTranslation({
                    field: `metrics_${metricId}_custom_value`,
                    language,
                    translations,
                    defaultValue: customNullValue
                });
            }
        }

        translatedCardMeta.visualization.metrics = metrics;
    }

    // Translate chart card meta
    if (translatedCardMeta.card_type === "Chart") {

        // Dimension label
        const dimConfig = translatedCardMeta.visualization?.dimension;
        const dimLabel = dimConfig?.label;
        if (dimLabel) {
            translatedCardMeta.visualization.dimension.label = getFieldTranslation({
                field: "dimension_label",
                language,
                translations,
                defaultValue: dimLabel
            });
        }

        // Dimension custom null value
        const dimNullLabel = dimConfig?.null_config?.custom_value;
        if (dimNullLabel) {
            translatedCardMeta.visualization.dimension.null_config.custom_value = getFieldTranslation({
                field: "dimension_custom_null",
                language,
                translations,
                defaultValue: dimNullLabel
            });
        }

        // Break by custom null value
        const breakByNullLabel = translatedCardMeta.visualization?.break_by?.null_config?.custom_value;
        if (breakByNullLabel) {
            translatedCardMeta.visualization.break_by.null_config.custom_value = getFieldTranslation({
                field: "break_by_custom_null",
                language,
                translations,
                defaultValue: breakByNullLabel
            });
        }

        // Bullet threshold
        const thresholdLabel = translatedCardMeta.visualization?.threshold?.label;
        if (thresholdLabel) {
            translatedCardMeta.visualization.threshold.label = getFieldTranslation({
                field: "threshold_label",
                language,
                translations,
                defaultValue: thresholdLabel
            });
        }

        // Heatmat
        const heatmapLabel = translatedCardMeta.visualization?.heatmap?.label;
        if (heatmapLabel) {
            translatedCardMeta.visualization.heatmap.label = getFieldTranslation({
                field: "heatmap_label",
                language,
                translations,
                defaultValue: heatmapLabel
            });
        }

        // Radius label
        const radiusConfig = translatedCardMeta.visualization?.radius;
        const radiusLabel = radiusConfig?.label;
        if (radiusLabel) {
            translatedCardMeta.visualization.radius.label = getFieldTranslation({
                field: "radius_label",
                language,
                translations,
                defaultValue: radiusLabel
            });
        }

        // Complements label
        const complements = translatedCardMeta.visualization?.complements;
        if (complements?.length > 0) {
            for (const complement of complements) {
                for (const config of complement.config) {
                    const configId = config.local_query_field.fieldId;
                    const complementLabel = config?.label;
    
                    if (complementLabel) {
                        config.label = getFieldTranslation({
                            field: `complement_${configId}_label`,
                            language,
                            translations,
                            defaultValue: complementLabel
                        });
                    }
                }
            }
    
            translatedCardMeta.visualization.complements = complements;
        }

        const axisConfig = translatedCardMeta.visualization?.axis;

        // X Axis label
        const xAxisLabel = axisConfig?.x_axis?.axis_label?.label;
        if (xAxisLabel) {
            translatedCardMeta.visualization.axis.x_axis.axis_label.label = getFieldTranslation({
                field: "x_axis_label",
                language,
                translations,
                defaultValue: xAxisLabel
            });
        }

        // Y Axis side a labels
        const yAxisSideA = axisConfig?.y_axis?.side_a;
        for (let i = 0; i < yAxisSideA?.length; i++) {
            const label = yAxisSideA[i]?.axis_label?.label;
            if (label) {
                translatedCardMeta.visualization.axis.y_axis.side_a[i].axis_label.label = getFieldTranslation({
                    field: `y_axis_side_a_${i}_label`,
                    language,
                    translations,
                    defaultValue: label
                });
            }
        }

        // Y Axis side b labels
        const yAxisSideB = axisConfig?.y_axis?.side_b;
        for (let i = 0; i < yAxisSideB?.length; i++) {
            const label = yAxisSideB[i]?.axis_label?.label;
            if (label) {
                translatedCardMeta.visualization.axis.y_axis.side_b[i].axis_label.label = getFieldTranslation({
                    field: `y_axis_side_b_${i}_label`,
                    language,
                    translations,
                    defaultValue: label
                });
            }
        }

        // Limit slice label
        const limitSliceLabel = translatedCardMeta.visualization?.graph_config?.limit_slices_options?.slices_name;
        if (limitSliceLabel) {
            translatedCardMeta.visualization.graph_config.limit_slices_options.slices_name = getFieldTranslation({
                field: "limit_slices_label",
                language,
                translations,
                defaultValue: limitSliceLabel
            });
        }
    }

    // Translate kpi card meta
    if (translatedCardMeta.card_type === "KPI") {
        const kpiGroups = translatedCardMeta.visualization?.kpiGroups;
        for (let i = 0; i < kpiGroups?.length; i++) {
            const kpiItems = kpiGroups[i]?.kpiItems;
            for (let j = 0; j < kpiItems?.length; j++) {
                // Translate label
                const kpiItemLabel = kpiGroups[i].kpiItems[j]?.textConfig?.value;
                if (kpiItemLabel) {
                    translatedCardMeta.visualization.kpiGroups[i].kpiItems[j].textConfig.value = getFieldTranslation({
                        field: `kpiItem_${kpiItems[j].local_query_field.fieldId}_value`,
                        language,
                        translations,
                        defaultValue: kpiItemLabel
                    });
                }

                // Translate conditional style label
                if (kpiItems[j]?.conditional_style_enabled) {
                    for (let h = 0; h < kpiGroups[i].kpiItems[j]?.conditional_style?.length; h++) {
                        const kpiItemConditionalLabel = kpiGroups[i].kpiItems[j]?.conditional_style[h]?.textConfig?.value;
                        if (kpiItemConditionalLabel) {
                            translatedCardMeta.visualization.kpiGroups[i].kpiItems[j].conditional_style[h].textConfig.value = getFieldTranslation({
                                field: `kpiItem_conditional_${kpiItems[j].local_query_field.fieldId}_value`,
                                language,
                                translations,
                                defaultValue: kpiItemConditionalLabel
                            });
                        }
                    }
                }
            }
        }
    }

    // Translate vertical table card meta
    if (translatedCardMeta.card_type === "VerticalTable") {
        const columns = translatedCardMeta.visualization?.table_columns;
        for (let i = 0; i < columns?.length; i++) {
            const columnTitle = columns[i]?.title;
            const fieldId = columns[i]?.local_query_field?.fieldId;
            if (columnTitle) {
                translatedCardMeta.visualization.table_columns[i].title = getFieldTranslation({
                    field: `column_${fieldId}_title`,
                    language,
                    translations,
                    defaultValue: columnTitle
                });
            }

            const footerTextValue = columns[i]?.footer?.value;
            if (footerTextValue) {
                translatedCardMeta.visualization.table_columns[i].footer.value = getFieldTranslation({
                    field: `column_${fieldId}_footer_value`,
                    language,
                    translations,
                    defaultValue: footerTextValue
                });
            }

            const customNullValue = columns[i]?.null_config?.custom_value;
            if (customNullValue) {
                translatedCardMeta.visualization.table_columns[i].null_config.custom_value = getFieldTranslation({
                    field: `column_${fieldId}_custom_value`,
                    language,
                    translations,
                    defaultValue: customNullValue
                });
            }
        }
    }

    // Translate cross table card meta
    if (translatedCardMeta.card_type === "CrossTable") {
        const columns = translatedCardMeta.visualization?.columns;
        for (let i = 0; i < columns?.length; i++) {
            const columnFieldId = columns[i].local_query_field.field;

            const columnLabel = columns[i]?.label;
            if (columnLabel) {
                translatedCardMeta.visualization.columns[i].label = getFieldTranslation({
                    field: `columns_${columnFieldId}_label`,
                    language,
                    translations,
                    defaultValue: columnLabel
                });
            }

            const customNullValue = columns[i]?.null_config?.custom_value;
            if (customNullValue) {
                translatedCardMeta.visualization.columns[i].null_config.custom_value = getFieldTranslation({
                    field: `columns_${columnFieldId}_custom_value`,
                    language,
                    translations,
                    defaultValue: customNullValue
                });
            }
        }

        const rows = translatedCardMeta.visualization?.rows;
        for (let i = 0; i < rows?.length; i++) {
            const rowFieldId = rows[i].local_query_field.fieldId;

            const rowLabel = rows[i]?.label;
            if (rowLabel) {
                translatedCardMeta.visualization.rows[i].label = getFieldTranslation({
                    field: `rows_${rowFieldId}_label`,
                    language,
                    translations,
                    defaultValue: rowLabel
                });
            }

            const customNullValue = rows[i]?.null_config?.custom_value;
            if (customNullValue) {
                translatedCardMeta.visualization.rows[i].null_config.custom_value = getFieldTranslation({
                    field: `rows_${rowFieldId}_custom_value`,
                    language,
                    translations,
                    defaultValue: customNullValue
                });
            }
        }
    }

    // Translate map card meta
    if (translatedCardMeta.card_type === "Map") {

        const mapSources = ['tooltip', 'popup'];

        const mapLayers = translatedCardMeta.visualization?.map_layers;
        for (let i = 0; i < mapLayers.length; i++) {

            if (mapLayers[i]?.color?.local_query_field) {
                const colorField = mapLayers[i].color.local_query_field.fieldId;
                const colorLabel = mapLayers[i].color.local_query_field.title;

                if (colorLabel) {
                    translatedCardMeta.visualization.map_layers[i].color.local_query_field.title = getFieldTranslation({
                        field: `${colorField}_label`,
                        language,
                        translations,
                        defaultValue: colorLabel
                    });
                }
            }

            for (let source of mapSources) {
                if (mapLayers[i][source]?.fields?.length > 0) {

                    for (let j = 0; j < mapLayers[i][source].fields.length; j++) {

                        const columnFieldId = mapLayers[i][source]?.fields[j].local_query_field.fieldId;
                        const columnLabel = mapLayers[i][source].fields[j]?.title;

                        if (columnLabel) {
                            translatedCardMeta.visualization.map_layers[i][source].fields[j].title = getFieldTranslation({
                                field: `${columnFieldId}_label`,
                                language,
                                translations,
                                defaultValue: columnLabel
                            });
                        }

                        const customNullValue = mapLayers[i][source].fields[j]?.null_config?.custom_value;
                        if (customNullValue) {
                            translatedCardMeta.visualization.map_layers[i][source].fields[j].null_config.custom_value = getFieldTranslation({
                                field: `${columnFieldId}_custom_null`,
                                language,
                                translations,
                                defaultValue: customNullValue
                            });
                        }
                    }
                }
            }
        }
    }


    return fromJS(translatedCardMeta);
}

export default {
    biuwerUILanguages,
    translationLanguages,
    getFieldTranslation,
    translateCardMeta,
    getLanguageFormat
}
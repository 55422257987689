import Papa from 'papaparse';
import moment from 'moment';
import numeral from 'numeral';
import { cloneDeep, trim, isNil, isNaN, get, set } from 'lodash';
import unwind from 'javascript-unwind';
import { formatNullValue } from './null-values-lib';
import { formatTimeMetric } from './time-lib';
import { parseDate } from './dates-lib';

// Constants
export const DEFAULT_DELIMITER = ';';
export const DEFAULT_LINE_BREAK = '\r';
export const DEFAULT_QUOTE_CHAR = '\\';

// Rows to be returned when parsing file with getMetadata action or processing file data with processData
export const DEFAULT_ROWS_TO_RETURN = 30;

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

// Name of the numeral custom locale for processing numeric data
const customNumeralLocale = 'csv-worker';

const dateExp = /([\d]+)([\-\./])([\d]+)([\-\./])([\d]+)|((Jan(|uary)|Feb(|ruary)|Mar(|ch)|Apr(|il)|May|Jun(|e)|Jul(|y)|Aug(|ust)|Sept(|ember)|Oct(|ober)|(Nov|Dec)(|ember))([\s\-])(|([\d]+){1,2}([\s\-]|\, ))([\d]+){4})/; // eslint-disable-line

/**
 * Internal aux function to convert dates from Lotus123 numeric format to Javascript native Date
 * Source: https://stackoverflow.com/a/16233621
 * @param {Number} serial
 * @returns
 */
const convertLotusToDate = (serial) => {
    let value = Number(serial);
    let utc_days  = Math.floor(value - 25569);
    let utc_value = utc_days * 86400;
    let date_info = new Date(utc_value * 1000);

    let fractional_day = value - Math.floor(value) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    let seconds = total_seconds % 60;

    total_seconds -= seconds;

    let hours = Math.floor(total_seconds / (60 * 60));
    let minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
};

/**
 * Parse given data with Papa parse
 * @param {String|Array} data Data formatted as String or Array of Arrays
 * @param {Object} options
 * @returns {Object}
 */
export async function parseData(data, options, dataToReturnOptions = {}, fieldsMetadata = null) {

    try {
        const { data: dataToParse, encoding } = await prepareDataForParser(data, { parserOptions: options, dataToReturnOptions, fieldsMetadata });
        let parseOptions = { ...options, encoding };
        parseOptions.quoteChar = parseOptions.quoteChar || DEFAULT_QUOTE_CHAR;
        if (data.constructor === Array) {
            parseOptions.delimiter = DEFAULT_DELIMITER;
            parseOptions.newLine = DEFAULT_LINE_BREAK;
        }

        let parsedData = Papa.parse(dataToParse, parseOptions);

        const fields = parsedData.meta.fields || parsedData.data[0].map((d, i) => `F${i+1}`);

        parsedData = parsedData.meta.fields ?
            parsedData.data.map(row => {
                delete(row.__parsed_extra);
                return row;
            }) :
            parsedData.data.map(row => {
                let rowToReturn = {};
                row.forEach((col, index) => {
                    rowToReturn[fields[index]] = col;
                });
                return rowToReturn;
            });

        const fieldsTypesMap = calculateFieldType(fields, parsedData, dataToReturnOptions);

        let metadata = [...fieldsTypesMap.entries()].map((entry) => {
            return {
                name: entry[0],
                originalType: entry[1][0],
                type: entry[1][1],
                fieldType: entry[1][1] === 'number' ? 'measure' : 'dimension'
            };
        });

        if (!!options.header) {
            metadata = metadata.map((field, index) => {
                const keyValue = `${index}@`;
                const splitedName = String(field.name).split(keyValue);
                const splitedNameLength = splitedName.length;
                const name =  splitedName.reverse().slice(0, splitedNameLength-1).reverse().join(keyValue);
                return { ...field, name };
            });
        }

        return {
            data: parsedData,
            metadata: metadata
        };
    } catch (err) {
        throw err;
    }
}

/**
 * Parse given data with Papa parse and return data formated
 * @param {String|Array} data Data formatted as String or Array of Arrays
 * @param {Object} options Parser configuration
 * @param {Object} formatOptions Data format configuration
 * @param {Object} currentMetadata (Optional)
 */
export async function parseAndFormatData(data, options, formatOptions, currentMetadata = null) {
    try {
        const { data: parsedData, metadata } = await parseData(data, options);

        const metadataToFormat = getMergedMetadata(metadata, currentMetadata);

        const formatedData = formatData(parsedData, metadataToFormat, formatOptions, 'es', options.rowsToSkip, options.rowsToReturn);

        return {
            data: formatedData,
            metadata: metadata,
            validated: validateColumns(formatedData, metadata, formatOptions, 'es')
        };
    } catch (err) {
        throw err;
    }
}

/**
 * Aux function to merge metadata obtained from parser lib and current metadata from previous parse/format function call
 * @param {Object} parserMetadata
 * @param {Object|null} currentMetadata
 * @returns
 */
const getMergedMetadata = (parserMetadata, currentMetadata = null) => {
    if (!currentMetadata) return parserMetadata;

    if (parserMetadata.length > currentMetadata.length) {
        return [...currentMetadata, ...parserMetadata.slice(currentMetadata.length)];
    } else {
        return [...currentMetadata.slice(0, parserMetadata.length)];
    }
};

/**
 *
 * @param {*} data
 * @param {*} metadata
 * @param {*} formatOptions
 * @returns
 */
export function processData(data, metadata, formatOptions, rowsToSkip, rowsToReturn) {
    try {
        const formatedData = formatData(data, metadata, formatOptions, 'es', rowsToSkip, rowsToReturn);

        return {
            data: formatedData,
            // metadata: metadata,
            validated: validateColumns(formatedData, metadata, formatOptions, 'es')
        };
    } catch (err) {
        throw err;
    }
}

/**
 * Internal function that estimate the data type for every data field returned by parser lib
 * @param {*} fields
 * @param {*} data
 * @param {*} dataToReturnOptions
 * @returns {Map}
 */
function calculateFieldType(fields, data, dataToReturnOptions) {
    const fieldsTypesMap = new Map(fields.map(field => [field, ['', '']]));
    for (let field of fields) {
        let j = 0;
        for (let row of data) {
            const value = row[field];
            const currentType = fieldsTypesMap.get(field);

            if (typeof value === 'number') {

                // Check if int or float
                if (Number.isInteger(value)) {
                    fieldsTypesMap.set(field, ['Int64', 'number']);
                } else {
                    fieldsTypesMap.set(field, ['Float64', 'number']);
                    break;
                }
            } else if (typeof value === 'string') {
                let format = dataToReturnOptions.dateFormat || undefined;
                let regExpMatch = dateExp.exec(value);
                // TODO: improve date validation
                if ((regExpMatch && regExpMatch[0]) && moment(value, format).isValid()) {
                    if ((['string', 'number']).indexOf(fieldsTypesMap.get(field)[1]) < 0) {
                        fieldsTypesMap.set(field, ['DateTime64', 'date']);
                    }
                } else if (typeof numeral(value).value() === 'number') {
                    fieldsTypesMap.set(field, ['Float64', 'number']);
                } else if (value !== '' && value !== 'NaN') {
                    fieldsTypesMap.set(field, ['String', 'string']);
                    break;
                }
            } else if (typeof value === 'boolean') {
                fieldsTypesMap.set(field, ['UInt8', 'boolean']);
            }
            j++;

            if (j === data.length && currentType === '') {
                fieldsTypesMap.set(field, ['String', 'string']);
            }
        }
    }
    return fieldsTypesMap;
}

/**
 * Format structured data with given parse options
 * @param {*} data
 * @param {*} parseOptions
 */
export function formatData(data, metadata, parseOptions, lang = 'es', rowsToSkip = 0, rowsToReturn = DEFAULT_ROWS_TO_RETURN) {
    let returnData = rowsToSkip > 0 ? data.slice(rowsToSkip) : data;
    returnData = rowsToReturn !== null ? returnData.slice(0, rowsToReturn) : returnData;

    return returnData.map((fileObject, i) => {
        const columns = [];
        Object.keys(fileObject).forEach((columnName, j) => {
            let value = fileObject[columnName],
                columnMetadata = metadata[j];

            if (value !== undefined && value !== null) {
                switch (columnMetadata.type) {
                    case 'date':
                        let format = parseOptions.dateFormat || undefined;
                        const parseDates = parseOptions.parseDates;
                        if (moment(value, format).isValid()) {
                            value = !parseDates ? moment(value, format).format(format) : moment(value, format).format('YYYY-MM-DD HH:mm:ss');
                        }
                        break;
                    case 'number':
                        // If is not a number or parseNumbers option is true, convert through mask
                        if (typeof value === 'string' || parseOptions.parseNumbers) {
                            const locale = customNumeralLocale,
                                defaultLocale = lang;

                            let numberFormat = {};
                            Object.keys(parseOptions.numberFormat || {}).forEach(key => {
                                if (parseOptions.numberFormat[key]) numberFormat[key] = parseOptions.numberFormat[key];
                            });

                            let extendedFormat = cloneDeep(numeral.locales[defaultLocale]) || { delimiters: {} };

                            if (Object.keys(numberFormat).length > 0) {
                                extendedFormat.delimiters = { ...extendedFormat.delimiters, ...numberFormat };
                            }

                            if (numeral.locales[locale]) {
                                numeral.locales[locale] = extendedFormat;
                            } else {
                                numeral.register('locale', locale, extendedFormat);
                            }

                            numeral.locale(locale);
                            value = numeral(value).value();
                            if (typeof value !== 'number') value = fileObject[columnName];
                            numeral.locale(defaultLocale);
                        }
                        break;
                    case 'boolean':
                        if (
                            (parseOptions.booleanFormat && parseOptions.booleanFormat.true && String(parseOptions.booleanFormat.true) === String(value)) ||
                            value === true) {
                            value = true;
                        } else if (
                            (parseOptions.booleanFormat && parseOptions.booleanFormat.false && String(parseOptions.booleanFormat.false) === String(value)) ||
                            value === false) {
                            value = false;
                        };
                        break;
                    case 'string':
                        value = trim(value);
                        break;
                    default:
                        break;
                }
            } else value = '';
            columns.push(value);
        });
        return columns;
    });
}

/**
 * Validate column data with data types given from metadata or format data options
 * @param {Array<Array<*>>} data
 * @param {Array<Object>} metadata
 * @param {Object} formatOptions
 * @param {String} lang
 * @returns {Array<Boolean>}
 */
function validateColumns(data, metadata, formatOptions, lang = 'es') {
    let validated = new Array(metadata.length).fill(true);
    metadata.forEach((meta, index) => {
        data.forEach(column => {
            const valueIsEmpty = isNil(column[index]) || column[index] === '';
            switch(meta.type) {
                case 'date':
                    if (!valueIsEmpty) {
                        let format = formatOptions.dateFormat || undefined;
                        let value = column[index];
                        let regExpMatch = dateExp.exec(value);
                        if (!regExpMatch || !moment(value, format).isValid()) {
                            validated[index] = false;
                        }
                    }
                    break;
                case 'boolean':
                    if (!valueIsEmpty) {
                        if (column[index] !== true && column[index] !== false) {
                            validated[index] = false;
                        }
                    }
                    break;
                case 'number':
                   // If not is a number, convert through mask
                   if (typeof column[index] === 'string' && !valueIsEmpty) {

                        const locale = customNumeralLocale,
                           defaultLocale = lang;

                        let numberFormat = {};
                        Object.keys(formatOptions.numberFormat || {}).forEach(key => {
                           if (formatOptions.numberFormat[key]) numberFormat[key] = formatOptions.numberFormat[key]
                        });

                        let extendedFormat = cloneDeep(numeral.locales[defaultLocale]) || { delimiters: {} };

                        if (Object.keys(numberFormat).length > 0) {
                            extendedFormat.delimiters = numberFormat;
                        }

                        if (numeral.locales[locale]) {
                            numeral.locales[locale] = extendedFormat;
                        } else {
                            numeral.register('locale', locale, extendedFormat);
                        }

                        numeral.locale(locale);
                        if (!numeral(column[index]).value()) validated[index] = false;
                        numeral.locale(defaultLocale);
                    }
                    break;
                case 'string':
                default:
                    break;
            }
        });
    });
    return validated;
}

/**
 * Internal function that prepares data to a format that parser lib can understand
 * TODO: case for Blob data
 * @param {*} data
 * @returns {Object}
 */
async function prepareDataForParser(data, { parserOptions = {}, dataToReturnOptions = {}, fieldsMetadata = null, delimiter = DEFAULT_DELIMITER, lineBreak = DEFAULT_LINE_BREAK, quoteChar = DEFAULT_QUOTE_CHAR}) {
    switch(data.constructor) {
        case String:
            return { data: data, encoding: 'UTF-8' };
        case Array:
            return {
                data: data
                    .map((row, rowIndex) => {
                        return row
                            .map((value, index) => {
                                let columnMetadata;
                                if (!!fieldsMetadata) {
                                    columnMetadata = fieldsMetadata[index];
                                }
                                if (columnMetadata && columnMetadata.type === 'date') {
                                    if (dataToReturnOptions.originDateFormat === 'lotus') {
                                        if (!isNil(value) && value !== '' && !isNaN(Number(value))) {
                                            value = moment(convertLotusToDate(value)).format(DEFAULT_DATE_FORMAT);
                                        }
                                    }
                                }
                                if (!!parserOptions.header && rowIndex === 0) {
                                    value = `${index}@${value}`;
                                }
                                return `${quoteChar}${value}${quoteChar}`;
                            })
                            .join(delimiter);
                    })
                    .join(lineBreak),
                encoding: 'UTF-8'
            };
        default:
            throw  new Error('Invalid data format');
    }
}

export const parseJsonData = (data, mappingConfig) => {
    let parsedData = [];
    data.forEach(object => parsedData.push(...parseJsonObject(object, mappingConfig)));

    return parsedData;
};

const deepUnwind = (arrayData, arrayPath) => {
    const unwindResult = [];
    const unwindAux = [];

    for (let objectData of arrayData) {
        const pathBeforeArray = arrayPath.split('.').slice(0, arrayPath.split('.').length-1).join('.');
        const arrayKey = arrayPath.split('.').slice(-1).join('.');

        let partialUnwindResult = unwind(pathBeforeArray.length > 0 ? get(objectData, pathBeforeArray) : objectData, arrayKey);
        partialUnwindResult = partialUnwindResult.map(unwindObject => {
            let resultObject = cloneDeep(objectData);
            set(resultObject, arrayPath, unwindObject[arrayKey]);
            return resultObject;
        });
        unwindAux.push(partialUnwindResult);
    }
    unwindAux.forEach(arr => unwindResult.push(...arr));
    return unwindResult;
};

const mapDeepUnwind = (arrayData, mappingConfig) => {
    const arrayPaths = [];
    for (let object of arrayData) {
        for (let fieldConfig of mappingConfig) {
            const path = fieldConfig.path.split('.');
            path.forEach((pathValue, index, fullPath) => {
                const tempPath = fullPath.slice(0, index+1 === fullPath.length ? undefined : index+1).join('.');
                if (Array.isArray(get(object, tempPath)) && arrayPaths.indexOf(tempPath) === -1) {
                    arrayPaths.push(tempPath);
                }
            });
        }
    }

    if (arrayPaths.length > 0) {
        for (let arrayPath of arrayPaths) {
            return mapDeepUnwind(deepUnwind(arrayData, arrayPath), mappingConfig);
        }
    } else {
        return arrayData;
    }
};

export const parseJsonObject = (object, mappingConfig) => {
    const unwindData = mapDeepUnwind([object], mappingConfig);
    return unwindData
        .map(unwindObject => {

            // Map JSON objects to return data based on mapping config
            let mappingResult = {};
            for (let fieldConfig of mappingConfig) {
                let value = get(unwindObject, fieldConfig.path);
                if (isNil(value) || typeof value === 'object') value = null;
                mappingResult[fieldConfig.name] = value;
            }
            return mappingResult;
        })
        .map(unparsedObject => {

            // Parse data to avoid data type errors. Data that wont match the field data type is converted to null
            let parsedObject = cloneDeep(unparsedObject);
            mappingConfig.forEach(field => {
                const fieldName = field.name;
                const fieldType = field.data_type;
                const fieldValue = parsedObject[fieldName];

                switch(fieldType) {
                    case 'number':
                        if (typeof fieldValue !== 'number') {
                            const parsedValue = numeral(fieldValue)
                            if (!parsedValue.value()) {
                                parsedObject[fieldName] = null
                            } else {
                                parsedObject[fieldName] = parsedValue.value()
                            }
                        }
                        break;
                    case 'date':
                    case 'datetime':
                        parsedObject[fieldName] = parseDate(fieldValue, fieldType)
                        break;
                    case 'boolean':
                        if (typeof fieldValue !== "boolean") {
                            if (fieldValue === "true") {
                                parsedObject[fieldName] = true
                            } else if (fieldValue === "false") {
                                parsedObject[fieldName] = false
                            } else {
                                parsedObject[fieldName] = null
                            }
                        } else {
                            if (fieldValue !== true && fieldValue !== false) parsedObject[fieldName] = null;
                        }
                        break;
                    case 'string':
                    default:
                        parsedObject[fieldName] = isNil(parsedObject[fieldName]) ? null : String(parsedObject[fieldName]);
                        break;
                }
            });

            return parsedObject;
        });
};

const parseAndFormatJsonData = (arrayData, mappingConfig) => {
    const parsedData = parseJsonData(arrayData, mappingConfig);

    return {
        data: parsedData,
        validated: validateJsonData(parsedData, mappingConfig)
    };
};

const validateJsonData = (data, metadata) => {
    let validated = Array(metadata.length).fill(true);
    metadata.forEach((field, index) => {
        const fieldName = field.name;
        data.forEach(row => {
            const valueIsEmpty = isNil(row[fieldName]) || row[fieldName] === '';
            switch(field.data_type) {
                case 'date':
                    if (!valueIsEmpty) {
                        let value = row[fieldName];
                        let regExpMatch = dateExp.exec(value);
                        if (!regExpMatch || !moment(value).isValid()) {
                            validated[index] = false;
                        }
                    }
                    break;
                case 'boolean':
                    if (!valueIsEmpty) {
                        if (row[fieldName] !== true && row[fieldName] !== false) {
                            validated[index] = false;
                        }
                    }
                    break;
                case 'number':
                    if (typeof row[fieldName] === 'string' && !valueIsEmpty) {
                        if (!numeral(row[fieldName]).value()) validated[index] = false;
                    }
                    break;
                case 'string':
                default:
                    break;
            }
        });
    });
    return validated;
};


/**
 * Check if value param is a JSON Object
 * @param {*} value
 * @returns {Boolean}
 */
export function isObject(value) {
    if (value == null) return false;
    return getObjectConstructorType(value) === "object";
}

/**
 * Verify if value param is instance of Object and check its constructor
 * @param {*} value
 * @returns {null | "object" | "array" | "date" | "map" | "set"}
 */
export function getObjectConstructorType(value) {
    if (value == null || !(value instanceof Object)) return null;

    const constructors = [
        { fn: Object, type: "object"},
        { fn: Array, type: "array" },
        { fn: Date, type: "date" },
        { fn: Map, type: "map" },
        { fn: Set, type: "set" }
    ];

    return constructors.find(({ fn }) => {
        return value.constructor === fn;
    })?.type || null;
}

/**
 * Return primitive value type based on instance of value param
 * @param {*} value
 * @returns {null | String}
 */
export function getValueType(value) {
    if (value == null) return null;
    if (value instanceof Object) return getObjectConstructorType(value);
    return typeof value;
}

export function parseObjectValueToString(value, field) {
    const valueType = getValueType(value);
    if (valueType === "object") return JSON.stringify(value);
    if (valueType === "array") return value.toString();

    if (field) {
        if (isNil(value) && field?.null_config) {
            value = formatNullValue(field?.null_config?.treatment, field?.null_config?.custom_value);
        }

        if (field?.time_metric && value) {
            value = formatTimeMetric(value, field?.time_level, field?.time_format);
        } else {
            if (field?.format_label && value) {
                value = numeral(value).format(field.format_label);
                if (field?.format_config?.number_type === "Currency") {

                    const currencyBefore = field?.format_config?.sign_position === "before" ? `${field?.format_config?.currency_sign} ` : '';
                    const currencyAfter = field?.format_config?.sign_position === "after" ? ` ${field?.format_config?.currency_sign}` : '';
                    value = `${currencyBefore}${value}${currencyAfter}`
                }
            }
            if (field?.format && value) value = moment(value).format(field.format);
        }
    }

    return value;
}

export default {
    parseData,
    formatData,
    processData,
    parseAndFormatData,
    parseAndFormatJsonData,
    DEFAULT_DELIMITER,
    DEFAULT_LINE_BREAK,
    DEFAULT_QUOTE_CHAR,
    DEFAULT_DATE_FORMAT,
    DEFAULT_ROWS_TO_RETURN,
    parseObjectValueToString,
    getValueType,
    isObject,
    getObjectConstructorType
};
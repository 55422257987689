import { PROFILES_FULL } from "../security/security-gql";
import { FILTERS_DETAIL } from "../../data/filters/filters-gql";

export const ACTIONS_FULL = `
    _id
    action_name
    action_type
    config
`;

export const CARD_QUERIES_FULL = `
    uuid
    queryId
    datamodel
    fields {
        _id
        position
        dataset
        dataField {
            _id
            alias
            data_type
            original_data_type
            calculated_field
            dataFieldName
        }
        field_type
        aggregation
        date_level
    }
    sort {
        fieldId
        order
        dataset
    }
    limit
    show_empty_rows
    show_row_limit_warning
    no_group_by
`;

export const CARD_NEW_DETAILS = `
    queries {
        ${CARD_QUERIES_FULL}
    }
    query_relations_type
    query_relations {
        type
        query1
        query2
        operations {
            field1
            field2
            operation
        }
    }
    query_combinations_main_query
    query_combinations {
        type
        query
    }
    local_datasets {
        _id
        queries
    }
    calculated_fields {
        _id
        position
        alias
        expression
        data_type
        field_type
        date_level
        local_dataset
    }
    visualization
    filters {
        ${FILTERS_DETAIL}
    }
    filters_config
    actions {
        ${ACTIONS_FULL}
    }
`;

export const CARD_NEW_DETAILS_BASIC = `
    filters {
        ${FILTERS_DETAIL}
    }
    actions {
        ${ACTIONS_FULL}
    }
`;

export const CARDS_LIST = `
    _id
    name
    desc
    title
    theme
    ${CARD_NEW_DETAILS_BASIC}
    auto_apply_filters
    multi_query
    card_type
    data_card
    show_footer
    show_title
    resource_token
    status
    height
    width
    demo
    ${PROFILES_FULL}
    created
    updated
    i18n {
        language
        field
        value
    }
`;

export const CARDS_LIST_BASIC = `
    _id
    name
    desc
    title
    theme
    height
    width
    show_footer
    show_title
    auto_apply_filters
    multi_query
    card_type
    data_card
    demo
    ${PROFILES_FULL}
    created
    updated
    resource_token
`;

export const CARDS_MINIMAL = `
    _id
    name
`;

export const CARDS_DETAIL = `
    _id
    name
    desc
    title
    theme
    auto_apply_filters
    multi_query
    card_type
    data_card
    show_row_limit_warning
    show_always_action_menu
    show_footer
    show_title
    resource_token
    status
    height
    width
    demo
    space_id {
        _id
        name
        space_type
        theme
    }
    i18n {
        language
        field
        value
    }
`;

export const CARDS_OPTIONS_FILTERS_DETAIL = `
    _id
    name
    desc
    title
    theme
    auto_apply_filters
    multi_query
    card_type
    data_card
    show_row_limit_warning
    show_always_action_menu
    show_footer
    show_title
    resource_token
    status
    height
    width
    demo
    space_id {
        _id
        name
        space_type
        theme
    }
    ${CARD_NEW_DETAILS}
    ${PROFILES_FULL}
`;

export const CARDS_ACTION_DIALOG = `
    _id
    name
    theme
    card_type
    space_id {
        _id
    }
    filters {
        ${FILTERS_DETAIL}
    }
`;

export const CARD_DETAIL = `
    _id
    name
    desc
    theme
    title
    card_type
    show_row_limit_warning
    show_always_action_menu
    show_footer
    show_title
    auto_apply_filters
    multi_query
    data_card
    height
    resource_token
    status
    space_id {
        _id
        name
        space_type
        theme
        i18n {
            language
            field
            value
        }
    }
    local_datasets {
        _id
        queries
    }
    ${CARD_NEW_DETAILS}
    ${PROFILES_FULL}
    i18n {
        language
        field
        value
    }
`;

export const CARD_DETAIL_SHARE = `
    _id
    name
    desc
    title
    theme
    card_type
    show_row_limit_warning
    show_always_action_menu
    show_footer
    show_title
    auto_apply_filters
    multi_query
    data_card
    height
    status
    ${CARD_NEW_DETAILS}
    profile_level
    i18n {
        language
        field
        value
    }
`;

// TODO BIUWER-1093 Visualization schema. only get file_name and file_size from visualization object
export const SPACE_CARDS_LIST = `
    _id
    name
    desc
    theme
    card_type
    show_title
    title
    height
    auto_apply_filters
    multi_query
    data_card
    filters {
        ${FILTERS_DETAIL}
    }
    actions {
        ${ACTIONS_FULL}
    }
    visualization
    space_id {
        _id
    }
    resource_token
    ${PROFILES_FULL}
    updated
    created
    i18n {
        language
        field
        value
    }
`;

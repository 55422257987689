import { Map as ImmutableMap, List, fromJS } from "immutable";
import { uniq } from "lodash";

// Libs
import expressionParser from "@biuwer/core/src/query-system/expression-parser";
import getLocalDatasets from "@biuwer/core/src/query-system/get-local-datasets";
import dceLib from "./data-card-editor-lib";

// Actions
import {
    // Data card editor metadata
    INITIALIZE_DCE,
    DCE_SET_CARD,
    DCE_SET_SAVED_CHANGES,
    DCE_ON_CHANGE_TAB,
    DCE_SET_SELECTED_PAGE_ID,
    // Queries Tab
    DCE_ON_CHANGE_SELECTED_QUERY_INDEX,
    // Queries
    DCE_ADD_CARD_QUERY, DCE_DELETE_CARD_QUERY,
    DCE_SET_QUERY_DATAMODEL,
    DCE_SET_QUERY_FIELDS,
    DCE_SET_QUERY_SORT,
    DCE_SET_QUERY_LIMIT,
    DCE_SET_QUERY_SHOW_EMPTY_ROWS,
    DCE_SET_NO_GROUP_BY,
    // Card query fields
    DCE_SET_CARD_QUERY_FIELD,
    DCE_DELETE_CARD_QUERY_FIELD,
    // Card query sort fields
    DCE_ADD_CARD_QUERY_SORT_FIELD,
    DCE_SET_CARD_QUERY_SORT_FIELD,
    DCE_DELETE_CARD_QUERY_SORT_FIELD,
    // Card query relations
    DCE_SET_CARD_QUERY_RELATIONS_TYPE,
    DCE_SET_CARD_QUERY_RELATIONS,
    // Card query combinations
    DCE_SET_CARD_QUERY_COMBINATIONS_MAIN_QUERY,
    DCE_SET_CARD_QUERY_COMBINATIONS,
    // Caculated fields
    DCE_SET_CALCULATED_FIELDS,
    DCE_DELETE_CALCULATED_FIELD,
    // Card visualization
    DCE_SET_CARD_VISUALIZATION,
    DCE_DELETE_CARD_VISUALIZATION_FIELD,
    // KPI visualization
    DCE_SET_KPI_GROUP_ITEMS,
    // VerticalTable visualization
    DCE_SET_TABLE_COLUMNS,
    DCE_SET_INTERACTIVE_COLUMNS,
    // CrossTable visualization
    DCE_SET_CT_ROWS,
    DCE_SET_CT_COLUMNS,
    DCE_SET_CT_METRICS,
    DCE_SET_CT_LOCAL_DATASET,
    // Chart visualization
    DCE_SET_CHART_DIMENSION,
    DCE_SET_CHART_BREAK_BY,
    DCE_SET_CHART_METRICS,
    DCE_SET_CHART_HEATMAP,
    DCE_SET_CHART_GRAPH_CONFIG,
    // Map visualization
    DCE_SET_MAP_LAYER_MATCHING_FIELD,
    DCE_SET_MAP_LAYER_LAT_FIELD,
    DCE_SET_MAP_LAYER_LNG_FIELD,
    DCE_SET_MAP_LAYER_SYMBOL_SIZE,
    DCE_SET_MAP_LAYER_COLOR,
    DCE_SET_MAP_LAYER_TOOLTIP_FIELDS,
    DCE_SET_MAP_LAYER_POPUP_FIELDS,
    DCE_SET_LAYER_INDEX,
    // Card actions
    DCE_SET_CARD_ACTIONS,
    DCE_SET_CHART_RADIUS,
    DCE_SET_CHART_THRESHOLD,
    DCE_SET_CHART_MIN_VALUE_FIELD,
    DCE_SET_CHART_MAX_VALUE_FIELD,
    DCE_SET_CARD_TRANSLATIONS
} from './data-card-editor-actions';

// Initial state
const initialState = ImmutableMap(fromJS({
    card: {},
    cardLoaded: false,
    savedChanges: false,
    selectedTab: 0,
    selectedPageId: null,
    queriesTab: {
        selectedQueryIndex: 0
    },
    canSubmit: false,
    failChecks: List([]),
    layerIndex: -1
}));


/**
 * Data card editor Reducers
 */
const dataCardEditor = (state = initialState, action) => {
    let newState = state;
    let fieldsToDelete = [];
    let calculatedFieldsToDelete = [];
    const selectedQueryIndex = state.getIn(["queriesTab", "selectedQueryIndex"]);
    const selectedQueryId = `Q${selectedQueryIndex + 1}`;
    const cardType = state.getIn(["card", "card_type"]);
    switch (action.type) {

        // General DCE
        case INITIALIZE_DCE:
            return initialState;

        case DCE_SET_CARD:
            newState = newState
                .set("card", newState.get("card").merge(action.card))
                .set("cardLoaded", true)
                .set("savedChanges", !action.forceChanges && !!state.get('cardLoaded'))
                ;
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_SAVED_CHANGES:
            return newState.set("savedChanges", action.savedChanges);
        case DCE_ON_CHANGE_TAB:
            return newState.set("selectedTab", action.selectedTab);

        case DCE_SET_SELECTED_PAGE_ID:
            return newState.set("selectedPageId", action.newSelectedPageId);

        case DCE_ON_CHANGE_SELECTED_QUERY_INDEX:
            return changeSelectedQueryIndex(newState, action.selectedQueryIndex);

        // Card queries actions

        case DCE_ADD_CARD_QUERY:

            newState = newState.updateIn(["card", "queries"], queries => queries.push(fromJS(action.cardQuery)));
            newState = newState.setIn(["card", "local_datasets"], fromJS(getLocalDatasets(newState.getIn(["card", "queries"])?.toJS(), newState.getIn(["card", "query_relations"])?.toJS())));

            newState = changeSelectedQueryIndex(newState, newState.getIn(["card", "queries"]).size - 1);
            return dceLib.checkCanSubmit(newState);

        case DCE_DELETE_CARD_QUERY:

            // Get card query index and id to delete
            const cardQueryIndexToDelete = action.cardQueryIndex;
            const cardQueryIdToDelete = `Q${cardQueryIndexToDelete + 1}`;

            // Add all query fields to delete
            newState.getIn(["card", "queries", cardQueryIndexToDelete, "fields"])
                .forEach(field => {
                    fieldsToDelete.push({
                        query: cardQueryIdToDelete,
                        position: field.get("position")
                    });
                })
            ;

            // Delete query
            newState = newState.updateIn(["card", "queries"], queries => queries.delete(cardQueryIndexToDelete));

            // Delete query relations
            newState = newState.updateIn(["card", "query_relations"], queryRelations => {
                return queryRelations.filter(queryRelation => {
                    return !(queryRelation.get("query1") === cardQueryIdToDelete || queryRelation.get("query2") === cardQueryIdToDelete);
                });
            });

            // Search each field in calculated fields and add needed to fields to delete
            calculatedFieldsToDelete = [];
            for (let fieldToDelete of fieldsToDelete) {
                calculatedFieldsToDelete = calculatedFieldsToDelete.concat(addCalculatedFieldsToDelete(newState, fieldToDelete));
            }
            fieldsToDelete = uniq(fieldsToDelete.concat(calculatedFieldsToDelete));

            // Delete calculated fields
            newState = deleteCalculatedFields(newState, fieldsToDelete);

            // Delete visualization fields
            newState = deleteVisualizationFields(newState, fieldsToDelete);

            // Reorder query ids
            newState = reOrderQueryIdDataCardFields(newState);

            // Re-position all needed fields metadata
            newState = rePositionDataCardFields(newState);

            // Change selected query index
            if (cardQueryIndexToDelete < selectedQueryIndex) {
                newState = changeSelectedQueryIndex(newState, selectedQueryIndex - 1);
            }
            else if (cardQueryIndexToDelete === selectedQueryIndex) {
                newState = changeSelectedQueryIndex(newState, 0);
            }

            // Recalculate local datasets
            if (newState.getIn(["card", "query_relations_type"]) === "joins") {
                newState = newState.setIn(["card", "local_datasets"], fromJS(getLocalDatasets(newState.getIn(["card", "queries"])?.toJS(), newState.getIn(["card", "query_relations"])?.toJS())));
            } else {
                newState = newState.setIn(["card", "local_datasets"], fromJS(getLocalDatasets(newState.getIn(["card", "queries"])?.toJS(), [],  newState.getIn(["card", "query_combinations"])?.toJS(), newState.getIn(["card", "query_combinations_main_query"]))));
            }

            return dceLib.checkCanSubmit(newState);

        case DCE_SET_QUERY_DATAMODEL:

            // Check if datamodel is valid and changes
            const newDatamodelId = action.datamodelId;
            if (!newDatamodelId || newDatamodelId === newState.getIn(["card", "queries", selectedQueryIndex, "datamodel"])) return newState;

            // Add all query fields to delete
            newState.getIn(["card", "queries", selectedQueryIndex, "fields"])
                .forEach(field => {
                    fieldsToDelete.push({
                        query: selectedQueryId,
                        position: field.get("position")
                    });
                })
            ;

            // Delete query fields and sort
            newState = newState.updateIn(["card", "queries", selectedQueryIndex], query => query.merge({ datamodel: newDatamodelId, fields: List([]), sort: List([]) }));

            // Delete query relations
            newState = newState.updateIn(["card", "query_relations"], queryRelations => {
                return queryRelations.filter(queryRelation => {
                    return !(queryRelation.get("query1") === selectedQueryId || queryRelation.get("query2") === selectedQueryId);
                });
            });

            // Search each field in calculated fields and add needed to fields to delete
            calculatedFieldsToDelete = [];
            for (let fieldToDelete of fieldsToDelete) {
                calculatedFieldsToDelete = calculatedFieldsToDelete.concat(addCalculatedFieldsToDelete(newState, fieldToDelete));
            }
            fieldsToDelete = uniq(fieldsToDelete.concat(calculatedFieldsToDelete));

            // Delete calculated fields
            newState = deleteCalculatedFields(newState, fieldsToDelete);

            // Delete visualization fields
            newState = deleteVisualizationFields(newState, fieldsToDelete);

            // Re-position all needed fields metadata
            newState = rePositionDataCardFields(newState);

            return dceLib.checkCanSubmit(newState);

        case DCE_SET_QUERY_FIELDS:

            // Update query fields
            newState = newState.setIn(["card", "queries", selectedQueryIndex, "fields"], action.fields);

            // Re-position all needed fields metadata
            newState = rePositionDataCardFields(newState);

            // Recalculate local datasets
            if (newState.getIn(["card", "query_relations_type"]) === "joins") {
                newState = newState.setIn(["card", "local_datasets"], fromJS(getLocalDatasets(newState.getIn(["card", "queries"])?.toJS(), newState.getIn(["card", "query_relations"])?.toJS())));
            } else {
                newState = newState.setIn(["card", "local_datasets"], fromJS(getLocalDatasets(newState.getIn(["card", "queries"])?.toJS(), [],  newState.getIn(["card", "query_combinations"])?.toJS(), newState.getIn(["card", "query_combinations_main_query"]))));
            }

            // Remove group by option if there is a measure field
            let numMeasures = 0;
            if (action?.fields?.size > 0) {
                action.fields.forEach((field) => {
                    if (field.get("field_type") === "measure") numMeasures++;
                });
                if (numMeasures > 0) newState = newState.setIn(["card", "queries", selectedQueryIndex, "no_group_by"], false);
            }
            return dceLib.checkCanSubmit(newState);

        case DCE_SET_QUERY_SORT:
            newState = newState.setIn(["card", "queries", selectedQueryIndex, "sort"], action.sort);
            return dceLib.checkCanSubmit(newState);

        case DCE_SET_QUERY_LIMIT:
            newState = newState.setIn(["card", "queries", selectedQueryIndex, "limit"], action.limit);
            return dceLib.checkCanSubmit(newState);

        case DCE_SET_QUERY_SHOW_EMPTY_ROWS:
            newState = newState.setIn(["card", "queries", selectedQueryIndex, "show_empty_rows"], action.showEmptyRows);
            return dceLib.checkCanSubmit(newState);

        case DCE_SET_NO_GROUP_BY:
            newState = newState.setIn(["card", "queries", selectedQueryIndex, "no_group_by"], action.noGroupBy);
            return dceLib.checkCanSubmit(newState);

        // Card query field actions

        case DCE_SET_CARD_QUERY_FIELD:

            // Check if changes field type
            let newFieldType;
            if (newState.getIn(["card", "queries", selectedQueryIndex, "fields", action.fieldIndex, "field_type"]) !== action.newField.get("field_type")) {
                newFieldType = action.newField.get("field_type");
            }
            // Update field
            newState = newState.updateIn(["card", "queries", selectedQueryIndex, "fields", action.fieldIndex], field => field.merge(action.newField));

            if (!newFieldType) return newState;

            // Check if new field type is forbidden in any visualization section
            switch (cardType) {
                case "KPI":
                    if (newFieldType === "measure") break;
                    fieldsToDelete.push({
                        query: selectedQueryId,
                        position: action.newField.get("position")
                    });
                    break;
                case "VerticalTable":
                    break;
                case "CrossTable":
                    switch (action.newField.get("field_type")) {
                        case "dimension":
                            // if new field type is dimension, it cannot be in metrics array
                            if (newState.getIn(["card", "visualization", "metrics"]).find(metric =>
                                metric.getIn(["local_query_field", "queryField", "position"]) === action.newField.get("position") &&
                                metric.getIn(["local_query_field", "queryField", "query"]) === selectedQueryId
                            )) {
                                fieldsToDelete.push({
                                    query: selectedQueryId,
                                    position: action.newField.get("position")
                                });
                            }
                            break;
                        case "measure":
                            // if new field type is measure, it cannot be in rows or columns arrays
                            // rows
                            if (newState.getIn(["card", "visualization", "rows"]).find(metric =>
                                metric.getIn(["local_query_field", "queryField", "position"]) === action.newField.get("position") &&
                                metric.getIn(["local_query_field", "queryField", "query"]) === selectedQueryId
                            )) {
                                fieldsToDelete.push({
                                    query: selectedQueryId,
                                    position: action.newField.get("position")
                                });
                            }
                            // columns
                            if (newState.getIn(["card", "visualization", "columns"]).find(metric =>
                                metric.getIn(["local_query_field", "queryField", "position"]) === action.newField.get("position") &&
                                metric.getIn(["local_query_field", "queryField", "query"]) === selectedQueryId
                            )) {
                                fieldsToDelete.push({
                                    query: selectedQueryId,
                                    position: action.newField.get("position")
                                });
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case "Chart":
                    // if new field type is measure, it cannot be in dimension or break by sections
                    // dimension
                    if (newFieldType === "measure" &&
                        newState.getIn(["card", "visualization", "dimension", "local_query_field", "queryField", "position"]) === action.newField.get("position") &&
                        newState.getIn(["card", "visualization", "dimension", "local_query_field", "queryField", "query"]) === selectedQueryId) {
                        fieldsToDelete.push({
                            query: selectedQueryId,
                            position: action.newField.get("position")
                        });
                    }

                    // break by
                    if (newFieldType === "measure" &&
                        newState.getIn(["card", "visualization", "break_by", "local_query_field", "queryField", "position"]) === action.newField.get("position") &&
                        newState.getIn(["card", "visualization", "break_by", "local_query_field", "queryField", "query"]) === selectedQueryId) {
                        fieldsToDelete.push({
                            query: selectedQueryId,
                            position: action.newField.get("position")
                        });
                    }

                    // if new field type is dimension, it cannot be in metrics array
                    if (newFieldType === "dimension" &&
                        newState.getIn(["card", "visualization", "metrics"]).find(metric =>
                        metric.getIn(["local_query_field", "queryField", "position"]) === action.newField.get("position") &&
                        metric.getIn(["local_query_field", "queryField", "query"]) === selectedQueryId
                    )) {
                        fieldsToDelete.push({
                            query: selectedQueryId,
                            position: action.newField.get("position")
                        });
                    }

                    break;
                default:
                    break;
            }

            if (fieldsToDelete.length === 0) return newState;

            // Delete visualization fields
            newState = deleteVisualizationFields(newState, fieldsToDelete);

            return dceLib.checkCanSubmit(newState);

        case DCE_DELETE_CARD_QUERY_FIELD:

            // Delete card query field
            const cardQueryFieldToDelete = newState.getIn(["card", "queries", selectedQueryIndex, "fields", action.fieldIndex]);
            newState = newState.updateIn(["card", "queries", selectedQueryIndex, "fields"], fields => fields.delete(action.fieldIndex));

            // Delete card query sort field
            const cardQuerySortFieldIndexToDelete = newState.getIn(["card", "queries", selectedQueryIndex, "sort"]).findIndex(sortField => {
                return sortField.get("fieldId") === cardQueryFieldToDelete.getIn(["dataField", "_id"]);
            });
            if (cardQuerySortFieldIndexToDelete !== -1) {
                newState = deleteCardQuerySortField(newState, selectedQueryIndex, cardQuerySortFieldIndexToDelete);
            }

            // Delete query relations
            newState = newState.updateIn(["card", "query_relations"], queryRelations => {
                return queryRelations.filter(queryRelation => {
                    return !(
                        (queryRelation.get("query1") === selectedQueryId || queryRelation.get("query2") === selectedQueryId) &&
                        (queryRelation.get("operations").some(operation => operation.get("field1") === cardQueryFieldToDelete.get("_id") || operation.get("field2") === cardQueryFieldToDelete.get("_id")))
                    );
                });
            });

            // Search field in calculated fields and add needed to fields to delete
            fieldsToDelete = uniq(fieldsToDelete.concat(addCalculatedFieldsToDelete(newState, { query: selectedQueryId, position: cardQueryFieldToDelete.get("position") })));

            // Delete calculated fields
            newState = deleteCalculatedFields(newState, fieldsToDelete);

            // Delete visualization fields
            newState = deleteVisualizationFields(newState, fieldsToDelete);

            // Re-position all needed fields metadata
            newState = rePositionDataCardFields(newState);

            return dceLib.checkCanSubmit(newState);

        // Card query sort field actions

        case DCE_ADD_CARD_QUERY_SORT_FIELD:
            newState = newState.updateIn(["card", "queries", selectedQueryIndex, "sort"], sortFields => sortFields.push(action.newSortField));
            return dceLib.checkCanSubmit(newState);

        case DCE_SET_CARD_QUERY_SORT_FIELD:
            newState = newState.updateIn(["card", "queries", selectedQueryIndex, "sort", action.sortFieldIndex], sortField => sortField.merge(action.newSortField));
            return dceLib.checkCanSubmit(newState);

        case DCE_DELETE_CARD_QUERY_SORT_FIELD:
            newState = deleteCardQuerySortField(newState, selectedQueryIndex, action.sortFieldIndex);
            return dceLib.checkCanSubmit(newState);

        // Card query relations actions
        case DCE_SET_CARD_QUERY_RELATIONS_TYPE:

            newState = newState.setIn(["card", "query_relations_type"], action.newCardQueryRelationsType);

            // Delete query_relations and query_combinations
            newState = newState.setIn(["card", "query_relations"], List([]));
            newState = newState.setIn(["card", "query_combinations"], List([]));

            // Recalculate local datasets
            newState = newState.setIn(["card", "local_datasets"], fromJS(getLocalDatasets(newState.getIn(["card", "queries"])?.toJS(), [], [])));

            return dceLib.checkCanSubmit(newState);

        case DCE_SET_CARD_QUERY_RELATIONS:

            newState = newState.setIn(["card", "query_relations"], action.newCardQueryRelations);

            // Recalculate local datasets
            newState = newState.setIn(["card", "local_datasets"], fromJS(getLocalDatasets(newState.getIn(["card", "queries"])?.toJS(), newState.getIn(["card", "query_relations"])?.toJS())));

            return dceLib.checkCanSubmit(newState);

        // Card query combinations actions
        case DCE_SET_CARD_QUERY_COMBINATIONS_MAIN_QUERY:
            newState = newState.setIn(["card", "query_combinations_main_query"], action.newCardQueryCombinationsMainQuery);
            return dceLib.checkCanSubmit(newState);

        case DCE_SET_CARD_QUERY_COMBINATIONS:
            newState = newState.setIn(["card", "query_combinations"], action.newCardQueryCombinations);

            // Recalculate local datasets
            newState = newState.setIn(["card", "local_datasets"], fromJS(getLocalDatasets(newState.getIn(["card", "queries"])?.toJS(), [],  newState.getIn(["card", "query_combinations"])?.toJS(), newState.getIn(["card", "query_combinations_main_query"]))));

            return dceLib.checkCanSubmit(newState);


        // Calculated fields actions

        case DCE_SET_CALCULATED_FIELDS:
            newState = newState.setIn(["card", "calculated_fields"], action.newCalculatedFields);
            return dceLib.checkCanSubmit(newState);

        case DCE_DELETE_CALCULATED_FIELD:

            // Delete calculated field
            const calculatedFieldToDelete = newState.getIn(["card", "calculated_fields", action.calculatedFieldIndex]);
            newState = newState.updateIn(["card", "calculated_fields"], calculatedFields => calculatedFields.delete(action.calculatedFieldIndex));

            // Search field in calculated fields and add needed to fields to delete
            fieldsToDelete = uniq(fieldsToDelete.concat(addCalculatedFieldsToDelete(newState, { query: "CF", position: calculatedFieldToDelete.get("position") })));

            // Delete calculated fields
            newState = deleteCalculatedFields(newState, fieldsToDelete);

            // Delete visualization fields
            newState = deleteVisualizationFields(newState, fieldsToDelete);

            // Re-position all needed fields metadata
            newState = rePositionDataCardFields(newState);

            return dceLib.checkCanSubmit(newState);

        // Card visualization actions

        case DCE_SET_CARD_VISUALIZATION:
            newState = newState.setIn(["card", "visualization"], action.newCardVisualization);
            return dceLib.checkCanSubmit(newState);

        case DCE_DELETE_CARD_VISUALIZATION_FIELD:
            newState = dceLib.cardPropertiesMatrix.deleteVisualizationField[cardType](newState, action.deleteFieldMetaData);
            return dceLib.checkCanSubmit(newState);

        // KPI visualization actions

        case DCE_SET_KPI_GROUP_ITEMS:
            newState = newState.setIn(["card", "visualization", "kpiGroups", action.kpiGroupIndex, "kpiItems"], action.newKpiGroupItems);
            return dceLib.checkCanSubmit(newState);

        // VerticalTable visualization actions

        case DCE_SET_TABLE_COLUMNS:

            // Update table columns
            newState = newState.setIn(["card", "visualization", "table_columns"], action.newTableColumns);

            // Check if need to delete a sort field
            const tableColumns = newState.getIn(["card", "visualization", "table_columns"]);
            const sortFields = newState.getIn(["card", "visualization", "table_settings", "default_sort_fields"]);
            let newSortFields = newState.getIn(["card", "visualization", "table_settings", "default_sort_fields"]);
            let newSortDirections = newState.getIn(["card", "visualization", "table_settings", "default_sort_directions"]);
            for (let sortField of sortFields) {
                if (!tableColumns.some(column => column.getIn(["local_query_field", "fieldId"]) === sortField)) {
                    const sortFieldIndex = newSortFields.findIndex(nsf => nsf === sortField);
                    newSortFields = newSortFields.delete(sortFieldIndex);
                    newSortDirections = newSortDirections.delete(sortFieldIndex);
                }
            }
            newState = newState.setIn(["card", "visualization", "table_settings", "default_sort_fields"], newSortFields);
            newState = newState.setIn(["card", "visualization", "table_settings", "default_sort_directions"], newSortDirections);

            return dceLib.checkCanSubmit(newState);

        case DCE_SET_INTERACTIVE_COLUMNS:
            newState = newState.setIn(["card", "visualization", "interactive_columns"], action.newInteractiveColumns);
            return dceLib.checkCanSubmit(newState);

        case DCE_SET_CT_ROWS:
            newState = newState.setIn(["card", "visualization", "rows"], action.newRows);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_CT_COLUMNS:
            newState = newState.setIn(["card", "visualization", "columns"], action.newColumns);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_CT_METRICS:
            newState = newState.setIn(["card", "visualization", "metrics"], action.newMetrics);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_CT_LOCAL_DATASET:
            newState = newState.setIn(["card", "visualization", "table_settings", "local_dataset"], action.newLocalDataset);
            return dceLib.checkCanSubmit(newState);

        case DCE_SET_CHART_DIMENSION:
            newState = newState.setIn(["card", "visualization", "dimension"], action.newDimension);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_CHART_BREAK_BY:
            newState = newState.setIn(["card", "visualization", "break_by"], action.newBreakBy);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_CHART_METRICS:
            newState = newState.setIn(["card", "visualization", "metrics"], action.newMetrics);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_CHART_HEATMAP:
            newState = newState.setIn(["card", "visualization", "heatmap"], action.newHeatmap);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_CHART_RADIUS:
            newState = newState.setIn(["card", "visualization", "radius"], action.newRadius);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_CHART_THRESHOLD:
            newState = newState.setIn(["card", "visualization", "threshold"], action.newThreshold);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_CHART_MIN_VALUE_FIELD:
            newState = newState.setIn(["card", "visualization", "graph_config", "min_value_field"], action.newMinValueField);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_CHART_MAX_VALUE_FIELD:
            newState = newState.setIn(["card", "visualization", "graph_config", "max_value_field"], action.newMaxValueField);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_CHART_GRAPH_CONFIG:
            newState = newState.setIn(["card", "visualization", "graph_config"], action.newGraphConfig)
            return dceLib.checkCanSubmit(newState)

        case DCE_SET_MAP_LAYER_MATCHING_FIELD:
            newState = newState.setIn(["card", "visualization", "map_layers", action.mapLayerIndex, "matching_field"], action.newMatchingField);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_MAP_LAYER_LAT_FIELD:
            newState = newState.setIn(["card", "visualization", "map_layers", action.mapLayerIndex, "lat_field"], action.newLatField);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_MAP_LAYER_LNG_FIELD:
            newState = newState.setIn(["card", "visualization", "map_layers", action.mapLayerIndex, "lng_field"], action.newLngField);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_MAP_LAYER_SYMBOL_SIZE:
            newState = newState.setIn(["card", "visualization", "map_layers", action.mapLayerIndex, "symbol", "size", "local_query_field"], action.newSymbolSize);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_MAP_LAYER_COLOR:
            newState = newState.setIn(["card", "visualization", "map_layers", action.mapLayerIndex, "color", "local_query_field"], action.newColor);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_MAP_LAYER_TOOLTIP_FIELDS:
            newState = newState.setIn(["card", "visualization", "map_layers", action.mapLayerIndex, "tooltip", "fields"], action.newTooltipFields);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_MAP_LAYER_POPUP_FIELDS:
            newState = newState.setIn(["card", "visualization", "map_layers", action.mapLayerIndex, "popup", "fields"], action.newPopupFields);
            return dceLib.checkCanSubmit(newState);
        case DCE_SET_LAYER_INDEX:
            return newState.setIn(["layerIndex"], action.newLayerIndex);

        // Card actions actions

        case DCE_SET_CARD_ACTIONS:
            return newState.setIn(["card", "actions"], action.newCardActions);

        // Card translations actions
        case DCE_SET_CARD_TRANSLATIONS:
            return newState.setIn(["card", "i18n"], action.newCardTranslations);

        default:
            return newState;
    }

};

export default dataCardEditor;

function changeSelectedQueryIndex(dceState, index) {
    return dceState.setIn(["queriesTab", "selectedQueryIndex"], index);
}

function deleteCardQuerySortField(dceState, selectedQueryIndex, index) {
    return dceState.updateIn(["card", "queries", selectedQueryIndex, "sort"], sortFields => sortFields.delete(index));
}

function deleteVisualizationFields(dceState, fieldsToDelete) {
    // Save deleted local query fields
    let deletedLQFieldIds = [];
    // Delete visualization fields
    dceState = dceState.updateIn(["card", "visualization"], visualization => {
        // Delete each needed local fields
        for (let fieldToDelete of fieldsToDelete) {
            // Custom delete for each card type
            switch (dceState.getIn(["card", "card_type"])) {
                case "KPI":
                    visualization = visualization.update("kpiGroups", kpiGroups => kpiGroups.map(kpiGroup => {
                        return kpiGroup.update("kpiItems", kpiItems => filterLocalQueryFieldElements(kpiItems, fieldToDelete.query, fieldToDelete.position, deletedLQFieldIds));
                    }));
                    break;
                case "VerticalTable":
                    const deletedTableColumns = [];
                    visualization = visualization
                        // Table columns
                        .update("table_columns", columns => columns.filter(column => {
                            const matchingField = dceLib.checkLocalQueryFieldElement(column, fieldToDelete.query, fieldToDelete.position);
                            if (matchingField) {
                                deletedLQFieldIds.push(column.getIn(["local_query_field", "fieldId"]));
                                deletedTableColumns.push(column.getIn(["local_query_field", "fieldId"]));
                            }
                            return !matchingField;
                        }))
                        // Interactive columns
                        .update("interactive_columns", columns => filterLocalQueryFieldElements(columns, fieldToDelete.query, fieldToDelete.position, deletedLQFieldIds))
                        // Default sort
                        .update("table_settings", tableSettings => {
                            let newDefaultSortFields = tableSettings.get("default_sort_fields");
                            let newDefaultSortDirections = tableSettings.get("default_sort_directions");
                            for (let deletedTableColumn of deletedTableColumns) {
                                const fieldIndex = newDefaultSortFields.findIndex(dsf => dsf === deletedTableColumn);
                                if (fieldIndex !== -1) {
                                    newDefaultSortFields = newDefaultSortFields.delete(fieldIndex);
                                    newDefaultSortDirections = newDefaultSortDirections.delete(fieldIndex);
                                }
                            }
                            return tableSettings.merge({
                                default_sort_fields: newDefaultSortFields,
                                default_sort_directions: newDefaultSortDirections
                            });
                        });
                    break;
                case "CrossTable":
                    visualization = visualization
                        .update("rows", rows => filterLocalQueryFieldElements(rows, fieldToDelete.query, fieldToDelete.position, deletedLQFieldIds))
                        .update("columns", columns => filterLocalQueryFieldElements(columns, fieldToDelete.query, fieldToDelete.position, deletedLQFieldIds))
                        .update("metrics", metrics => filterLocalQueryFieldElements(metrics, fieldToDelete.query, fieldToDelete.position, deletedLQFieldIds));
                    break;
                case "Chart":
                    visualization = visualization.update("metrics", metrics => filterLocalQueryFieldElements(metrics, fieldToDelete.query, fieldToDelete.position, deletedLQFieldIds));

                    const dimension = visualization.get('dimension') && filterLocalQueryFieldElements(List().push(visualization.get("dimension")), fieldToDelete.query, fieldToDelete.position, deletedLQFieldIds);
                    const breakBy = visualization.get('break_by') && filterLocalQueryFieldElements(List().push(visualization.get("break_by")), fieldToDelete.query, fieldToDelete.position, deletedLQFieldIds);
                    const threshold = visualization.get('threshold') && filterLocalQueryFieldElements(List().push(visualization.get("threshold")), fieldToDelete.query, fieldToDelete.position, deletedLQFieldIds);
                    const heatmap = visualization.get('heatmap') && filterLocalQueryFieldElements(List().push(visualization.get("heatmap")), fieldToDelete.query, fieldToDelete.position, deletedLQFieldIds);

                    if (dimension && dimension.size === 0) visualization = visualization.update("dimension", () => ImmutableMap({}));
                    if (breakBy && breakBy.size === 0) visualization = visualization.update("break_by", () => ImmutableMap({}));
                    if (threshold && threshold.size === 0) visualization = visualization.update("threshold", () => ImmutableMap({}));
                    if (heatmap && heatmap.size === 0) visualization = visualization.update("heatmap", () => ImmutableMap({}));

                    if (visualization.get('complements')?.size > 0) {
                        visualization = visualization.update("complements", (complements) => 
                            complements.filter((complement) => filterLocalQueryFieldElements(complement.get("config"), fieldToDelete.query, fieldToDelete.position, deletedLQFieldIds).size > 0)
                        );
                    }

                    break;
                case "Map":
                    visualization = visualization.update("map_layers", mapLayers => mapLayers.map(mapLayer => {
                        let newMapLayer = mapLayer;
                        // Lat field
                        if (newMapLayer.get("lat_field")) {
                            const latField = dceLib.checkLocalQueryFieldElement(ImmutableMap({ "local_query_field": newMapLayer.get("lat_field") }), fieldToDelete.query, fieldToDelete.position);
                            if (latField) {
                                deletedLQFieldIds.push(newMapLayer.getIn(['lat_field', 'fieldId']));
                                newMapLayer = newMapLayer.set("lat_field", undefined);
                            }
                        }
                        // Lng field
                        if (newMapLayer.get("lng_field")) {
                            const lngField = dceLib.checkLocalQueryFieldElement(ImmutableMap({ "local_query_field": newMapLayer.get("lng_field") }), fieldToDelete.query, fieldToDelete.position);
                            if (lngField) {
                                deletedLQFieldIds.push(newMapLayer.getIn(['lng_field', 'fieldId']));
                                newMapLayer = newMapLayer.set("lng_field", undefined);
                            }
                        }
                        // Matching field
                        if (newMapLayer.get("matching_field")) {
                            const matchingField = dceLib.checkLocalQueryFieldElement(ImmutableMap({ "local_query_field": newMapLayer.get("matching_field") }), fieldToDelete.query, fieldToDelete.position);
                            if (matchingField) {
                                deletedLQFieldIds.push(newMapLayer.getIn(['matching_field', 'fieldId']));
                                newMapLayer = newMapLayer.set("matching_field", undefined);
                            }
                        }
                        // Symbol size field
                        if (newMapLayer.getIn(['symbol', 'size', 'local_query_field'])) {
                            const matchingField = dceLib.checkLocalQueryFieldElement(newMapLayer.getIn(['symbol', 'size']), fieldToDelete.query, fieldToDelete.position);
                            if (matchingField) {
                                deletedLQFieldIds.push(newMapLayer.getIn(['symbol', 'size', 'local_query_field', 'fieldId']));
                                newMapLayer = newMapLayer.setIn(['symbol', 'size', 'local_query_field'], undefined);
                            }
                        }
                        // Color size field
                        if (newMapLayer.getIn(['color', 'local_query_field'])) {
                            const matchingField = dceLib.checkLocalQueryFieldElement(newMapLayer.get('color'), fieldToDelete.query, fieldToDelete.position);
                            if (matchingField) {
                                deletedLQFieldIds.push(newMapLayer.getIn(['color', 'local_query_field', 'fieldId']));
                                newMapLayer = newMapLayer.setIn(['color', 'local_query_field'], undefined);
                            }
                        }
                        // Tooltip fields
                        if (newMapLayer.getIn(['tooltip', 'fields'])) {
                            newMapLayer = newMapLayer.updateIn(['tooltip', 'fields'], tooltipFields => tooltipFields.filter(tooltipField => {
                                const match = dceLib.checkLocalQueryFieldElement(tooltipField, fieldToDelete.query, fieldToDelete.position);
                                if (match) deletedLQFieldIds.push(tooltipField.getIn(['local_query_field', 'fieldId']));
                                return !match;
                            }));
                        }
                        // Popup fields
                        if (newMapLayer.getIn(['popup', 'fields'])) {
                            newMapLayer = newMapLayer.updateIn(['popup', 'fields'], popupFields => popupFields.filter(popupField => {
                                const match = dceLib.checkLocalQueryFieldElement(popupField, fieldToDelete.query, fieldToDelete.position);
                                if (match) deletedLQFieldIds.push(popupField.getIn(['local_query_field', 'fieldId']));
                                return !match;
                            }));
                        }
                        return newMapLayer;
                    }))
                    break
                default:
                    break;
            }
        }
        return visualization;
    });
    // Delete linked actions of deleted local query fields
    dceState = dceState.updateIn(['card', 'actions'], actions => dceLib.deleteActionsWithDeletedFields(actions, deletedLQFieldIds));
    // Delete conditional styles of deleted local query fields
    dceState = dceLib.deleteConditionalStylesWithDeletedFields({ dceState, deletedFieldIds: deletedLQFieldIds });
    return dceState;
}

function filterLocalQueryFieldElements(localQueryFieldElements, query, position, deletedLQFieldIds) {
    return localQueryFieldElements.filter(localQueryFieldElement => {
        const match = dceLib.checkLocalQueryFieldElement(localQueryFieldElement, query, position);
        if (match && deletedLQFieldIds) deletedLQFieldIds.push(localQueryFieldElement.getIn(['local_query_field', 'fieldId']));
        return !match;
    })
}



function getItemWithNewFieldPosition(newQueryPositions, item) {
    const newCardQueryFieldPosition = newQueryPositions.find(field => {
        return (
            field.query === item.getIn(["local_query_field", "queryField", "query"]) &&
            field.position === item.getIn(["local_query_field", "queryField", "position"])
        );
    });
    if (!newCardQueryFieldPosition) return item;

    // Check if item has a total of type "formula" and changed it with new field position
    let newItem = item.setIn(["local_query_field", "queryField", "position"], newCardQueryFieldPosition.newPosition);
    if (item.getIn(["footer", "type"]) === "formula") {
        let splittedFormula = item.getIn(["footer", "value"]).split("}");

        if (splittedFormula?.length > 0) {
            splittedFormula = splittedFormula.map((f) => {
                const query = f.substring(f.indexOf("{Q") + 1, f.indexOf("."));
                const position = f.substring(f.indexOf(".") + 1, f.indexOf(" "));
                const newFieldPosition = newQueryPositions.find(field => field.query === query && field.position === Number(position));

                if (newFieldPosition?.newPosition) {
                    return f.replace(`${newFieldPosition.query}.${newFieldPosition.position}`, `${newFieldPosition.query}.${newFieldPosition.newPosition}`);
                } else {
                    return f;
                }
            });
        }
        newItem = newItem.setIn(["footer", "value"], splittedFormula.join("}"));
    }
    return newItem;
}

function getItemWithNewFieldQueryId(newQueryIds, item) {
    const newCardQueryFieldQueryId = newQueryIds.find(field => field.queryId === item.getIn(["local_query_field", "queryField", "query"]));
    if (!newCardQueryFieldQueryId) return item;
    return item.setIn(["local_query_field", "queryField", "query"], newCardQueryFieldQueryId.newQueryId);
}

function rePositionDataCardFields(newState) {

    // Initiate new query positions array
    const newQueryPositions = [];

    // Reposition queries
    newState = newState.updateIn(["card", "queries"], queries => {
        return queries.map(query => {
            return query.update("fields", fields => {
                return fields.map((field, fieldIndex) => {
                    const newPosition = fieldIndex + 1;
                    newQueryPositions.push({ query: query.get("queryId"), position: field.get("position"), newPosition });
                    return field.merge({ position: newPosition });
                });
            });
        });
    });

    // Reposition calculated fields
    newState = newState.updateIn(["card", "calculated_fields"], calculatedFields => {
        return calculatedFields.map((calculatedField, calculatedFieldIndex) => {
            const newPosition = calculatedFieldIndex + 1;
            newQueryPositions.push({ query: "CF", position: calculatedField.get("position"), newPosition });
            return calculatedField.merge({ position: newPosition });
        });
    });

    // Change to new position in calculated fields
    newState = newState.updateIn(["card", "calculated_fields"], calculatedFields => {
        return calculatedFields.map(calculatedField => {

            // Find fields in expression and replace the position number of affected fields
            const expression = calculatedField.get("expression");
            const regexFields = /{.*?}/g;
            const regexQueryPosition = /(?<query>Q[1-9][0-9]*|CF)\.(?<position>[1-9][0-9]*)/;
            const fields = expression.match(regexFields);

            if (!fields) return calculatedField;

            const newFields = [];

            for (let field of fields) {

                const queryPositionMatch = field.match(regexQueryPosition);
                const newFieldPosition = newQueryPositions.find(nfp => {
                    return (
                        nfp.query === queryPositionMatch.groups.query &&
                        nfp.position === Number(queryPositionMatch.groups.position)
                    );
                });

                if (!newFieldPosition) {
                    newFields.push(field);
                    continue;
                }

                newFields.push(field.replace(
                    `{${queryPositionMatch.groups.query}.${queryPositionMatch.groups.position}`,
                    `{${queryPositionMatch.groups.query}.${newFieldPosition.newPosition}`
                ));

            }

            let newExpression = expression;
            fields.forEach((field, index) => {
                newExpression = newExpression.replace(field, newFields[index]);
            });
            return calculatedField.set("expression", newExpression);

        });
    });

    // Change to new position in visualization
    switch (newState.getIn(["card", "card_type"])) {
        case "KPI":
            // Update needed KPI items
            newState = newState.updateIn(["card", "visualization", "kpiGroups"], kpiGroups => {
                return kpiGroups.map(kpiGroup => kpiGroup.update("kpiItems", kpiItems => kpiItems.map(kpiItem => {
                    return getItemWithNewFieldPosition(newQueryPositions, kpiItem);
                })))
            });
            break;
        case "VerticalTable":
            // Update needed table columns
            newState = newState.updateIn(["card", "visualization", "table_columns"], tableColumns => tableColumns.map(column => {
                return getItemWithNewFieldPosition(newQueryPositions, column);
            }));
            // Update needed interactive columns
            newState = newState.updateIn(["card", "visualization", "interactive_columns"], tableColumns => tableColumns.map(column => {
                return getItemWithNewFieldPosition(newQueryPositions, column);
            }));
            break;
        case "CrossTable":
            // Update needed rows
            newState = newState.updateIn(["card", "visualization", "rows"], rows => rows.map(row => {
                return getItemWithNewFieldPosition(newQueryPositions, row);
            }));

            // Update needed columns
            newState = newState.updateIn(["card", "visualization", "columns"], columns => columns.map(column => {
                return getItemWithNewFieldPosition(newQueryPositions, column);
            }));

            // Update needed metrics
            newState = newState.updateIn(["card", "visualization", "metrics"], metrics => metrics.map(metric => {
                return getItemWithNewFieldPosition(newQueryPositions, metric);
            }));

            break;
        case "Chart":
            // Update needed metrics
            if (newState.getIn(['card', 'visualization', 'metrics']) && newState.getIn(['card', 'visualization', 'metrics']).size) {
                newState = newState.updateIn(["card", "visualization", "metrics"], metrics => metrics.map(metric => {
                    return getItemWithNewFieldPosition(newQueryPositions, metric);
                }));
            }

            // Update dimension
            if (newState.getIn(['card', 'visualization', 'dimension'])) {
                newState = newState.updateIn(["card", "visualization", "dimension"], dimension => getItemWithNewFieldPosition(newQueryPositions, dimension));
            }

            // Update break by
            if (newState.getIn(['card', 'visualization', 'break_by'])) {
                newState = newState.updateIn(["card", "visualization", "break_by"], breakBy => getItemWithNewFieldPosition(newQueryPositions, breakBy));
            }

            break;
        case "Map":
            // Loop through each map layer
            newState = newState.updateIn(['card', 'visualization', 'map_layers'], mapLayers => mapLayers.map(mapLayer => {
                let newMapLayer = mapLayer;
                // Lat field
                if (newMapLayer.get("lat_field")) {
                    const newField = getItemWithNewFieldPosition(newQueryPositions, ImmutableMap({ 'local_query_field': newMapLayer.get("lat_field") }));
                    newMapLayer = newMapLayer.set("lat_field", newField.get('local_query_field'));
                }
                // Lng field
                if (newMapLayer.get("lng_field")) {
                    const newField = getItemWithNewFieldPosition(newQueryPositions, ImmutableMap({ 'local_query_field': newMapLayer.get("lng_field") }));
                    newMapLayer = newMapLayer.set("lng_field", newField.get('local_query_field'));
                }
                // Matching field
                if (newMapLayer.get("matching_field")) {
                    const newField = getItemWithNewFieldPosition(newQueryPositions, ImmutableMap({ 'local_query_field': newMapLayer.get("matching_field") }));
                    newMapLayer = newMapLayer.set("matching_field", newField.get('local_query_field'));
                }
                // Symbol size field
                if (newMapLayer.getIn(['symbol', 'size', 'local_query_field'])) {
                    const newField = getItemWithNewFieldPosition(newQueryPositions, newMapLayer.getIn(['symbol', 'size']));
                    newMapLayer = newMapLayer.setIn(['symbol', 'size'], newField);
                }
                // Color size field
                if (newMapLayer.getIn(['color', 'local_query_field'])) {
                    const newField = getItemWithNewFieldPosition(newQueryPositions, newMapLayer.get('color'));
                    newMapLayer = newMapLayer.set('color', newField);
                }
                // Tooltip fields
                if (newMapLayer.getIn(['tooltip', 'fields'])) {
                    newMapLayer = newMapLayer.updateIn(['tooltip', 'fields'], tooltipFields => tooltipFields.map(tooltipField => {
                        return getItemWithNewFieldPosition(newQueryPositions, tooltipField);
                    }));
                }
                // Popup fields
                if (newMapLayer.getIn(['popup', 'fields'])) {
                    newMapLayer = newMapLayer.updateIn(['popup', 'fields'], popupFields => popupFields.map(popupField => {
                        return getItemWithNewFieldPosition(newQueryPositions, popupField);
                    }));
                }
                return newMapLayer;
            }));
            break;
        default:
            break;
    }

    return newState;

}

function addCalculatedFieldsToDelete(newState, fieldQueryPosition) {

    const fieldsToDelete = [];
    const originalCalculatedFields = newState.getIn(["card", "calculated_fields"]).map(calculatedField => {
        const parsedExpression = expressionParser.parseExpression(calculatedField.get("expression"));
        return calculatedField.set("parsedExpression", parsedExpression);
    });

    dceLib.checkFieldInCalculatedFields({ calculatedFields: originalCalculatedFields, fieldQueryPosition, fieldsToDelete });

    return fieldsToDelete;

}

function deleteCalculatedFields(newState, fieldsToDelete) {
    const calculatedFieldsToDelete = fieldsToDelete.filter(f => f.query === "CF");
    return newState.updateIn(["card", "calculated_fields"], calculatedFields => {
        return calculatedFields.filter(cf => !calculatedFieldsToDelete.some(f => f.position === cf.get("position")));
    });
}

function reOrderQueryIdDataCardFields(newState) {

    // Initiate new query ids array
    const newQueryIds = [];

    // Reorder query ids
    newState = newState.updateIn(["card", "queries"], queries => {
        return queries.map((query, index) => {
            const newQueryId = `Q${index + 1}`;
            newQueryIds.push({ queryId: query.get("queryId"), newQueryId });
            return query.set("queryId", newQueryId);
        });
    });

    // Reorder query relations
    newState = newState.updateIn(["card", "query_relations"], queryRelations => {
        return queryRelations.map(queryRelation => {
            const newRelationQueryId1 = newQueryIds.find(nqid => nqid.queryId === queryRelation.get("query1"));
            const newRelationQueryId2 = newQueryIds.find(nqid => nqid.queryId === queryRelation.get("query2"));
            return queryRelation.merge({
                query1: newRelationQueryId1 ? newRelationQueryId1.newQueryId : queryRelation.get("query1"),
                query2: newRelationQueryId2 ? newRelationQueryId2.newQueryId : queryRelation.get("query2")
            });
        });
    });

    // Change to new queryId in calculated fields
    newState = newState.updateIn(["card", "calculated_fields"], calculatedFields => {
        return calculatedFields.map(calculatedField => {

            // Find fields in expression and replace the position number of affected fields
            const expression = calculatedField.get("expression");
            const regexFields = /{.*?}/g;
            const regexQueryId = /(?<query>Q[1-9][0-9]*)\./;
            const fields = expression.match(regexFields);

            if (!fields) return calculatedField;

            const newFields = [];

            for (let field of fields) {

                const queryIdMatch = field.match(regexQueryId);
                const newFieldQueryId = newQueryIds.find(nqid => nqid.queryId === queryIdMatch?.groups?.query);

                if (!newFieldQueryId) {
                    newFields.push(field);
                    continue;
                }

                newFields.push(field.replace(
                    `{${queryIdMatch?.groups?.query}.`,
                    `{${newFieldQueryId.newQueryId}.`
                ));

            }

            let newExpression = expression;
            fields.forEach((field, index) => {
                newExpression = newExpression.replace(field, newFields[index]);
            });
            return calculatedField.set("expression", newExpression);

        });
    });

    // Change to new query id in visualization
    switch (newState.getIn(["card", "card_type"])) {
        case "KPI":
            // Update needed KPI items
            newState = newState.updateIn(["card", "visualization", "kpiGroups"], kpiGroups => {
                return kpiGroups.map(kpiGroup => kpiGroup.update("kpiItems", kpiItems => kpiItems.map(kpiItem => {
                    return getItemWithNewFieldQueryId(newQueryIds, kpiItem);
                })));
            });
            break;
        case "VerticalTable":
            // Update needed table columns
            newState = newState.updateIn(["card", "visualization", "table_columns"], tableColumns => tableColumns.map(column => {
                return getItemWithNewFieldQueryId(newQueryIds, column);
            }));
            // Update needed interactive columns
            newState = newState.updateIn(["card", "visualization", "interactive_columns"], tableColumns => tableColumns.map(column => {
                return getItemWithNewFieldQueryId(newQueryIds, column);
            }));
            break;
        case "CrossTable":
            newState = newState.updateIn(["card", "visualization", "columns"], columns => columns.map(column => {
                return getItemWithNewFieldQueryId(newQueryIds, column);
            }));
            newState = newState.updateIn(["card", "visualization", "rows"], rows => rows.map(row => {
                return getItemWithNewFieldQueryId(newQueryIds, row);
            }));
            newState = newState.updateIn(["card", "visualization", "metrics"], metrics => metrics.map(metric => {
                return getItemWithNewFieldQueryId(newQueryIds, metric);
            }));
            break;
        case "Chart":
            if (newState.getIn(["card", "visualization", "dimension"])) {
                newState = newState.updateIn(["card", "visualization", "dimension"], dimension => getItemWithNewFieldQueryId(newQueryIds, dimension));
            }
            newState = newState.updateIn(["card", "visualization", "metrics"], metrics => metrics.map(metric => {
                return getItemWithNewFieldQueryId(newQueryIds, metric);
            }));
            if (newState.getIn(["card", "visualization", "break_by"])) {
                newState = newState.updateIn(["card", "visualization", "break_by"], breakBy => getItemWithNewFieldQueryId(newQueryIds, breakBy));
            }
            if (newState.getIn(["card", "visualization", "radius"])) {
                newState = newState.updateIn(["card", "visualization", "radius"], radius => getItemWithNewFieldQueryId(newQueryIds, radius));
            }
            break;
        case "Map":
            // Loop through each map layer
            newState = newState.updateIn(['card', 'visualization', 'map_layers'], mapLayers => mapLayers.map(mapLayer => {
                let newMapLayer = mapLayer;
                // Lat field
                if (newMapLayer.get("lat_field")) {
                    const newField = getItemWithNewFieldQueryId(newQueryIds, ImmutableMap({ 'local_query_field': newMapLayer.get("lat_field") }));
                    newMapLayer = newMapLayer.set("lat_field", newField.get('local_query_field'));
                }
                // Lng field
                if (newMapLayer.get("lng_field")) {
                    const newField = getItemWithNewFieldQueryId(newQueryIds, ImmutableMap({ 'local_query_field': newMapLayer.get("lng_field") }));
                    newMapLayer = newMapLayer.set("lng_field", newField.get('local_query_field'));
                }
                // Matching field
                if (newMapLayer.get("matching_field")) {
                    const newField = getItemWithNewFieldQueryId(newQueryIds, ImmutableMap({ 'local_query_field': newMapLayer.get("matching_field") }));
                    newMapLayer = newMapLayer.set("matching_field", newField.get('local_query_field'));
                }
                // Symbol size field
                if (newMapLayer.getIn(['symbol', 'size', 'local_query_field'])) {
                    const newField = getItemWithNewFieldQueryId(newQueryIds, newMapLayer.getIn(['symbol', 'size']));
                    newMapLayer = newMapLayer.setIn(['symbol', 'size'], newField);
                }
                // Color size field
                if (newMapLayer.getIn(['color', 'local_query_field'])) {
                    const newField = getItemWithNewFieldQueryId(newQueryIds, newMapLayer.get('color'));
                    newMapLayer = newMapLayer.set('color', newField);
                }
                // Tooltip fields
                if (newMapLayer.getIn(['tooltip', 'fields'])) {
                    newMapLayer = newMapLayer.updateIn(['tooltip', 'fields'], tooltipFields => tooltipFields.map(tooltipField => {
                        return getItemWithNewFieldQueryId(newQueryIds, tooltipField);
                    }));
                }
                // Popup fields
                if (newMapLayer.getIn(['popup', 'fields'])) {
                    newMapLayer = newMapLayer.updateIn(['popup', 'fields'], popupFields => popupFields.map(popupField => {
                        return getItemWithNewFieldQueryId(newQueryIds, popupField);
                    }));
                }
                return newMapLayer;
            }));
            break;
        default:
            break;
    }

    return newState;

}